import { createContext, useEffect, useMemo, useReducer } from "react";
import PropTypes from "prop-types";
// utils
import axios from "../utils/axios";
import { isValidToken, setSession } from "../utils/jwt";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  isInitialized: false,
  isRegistered: false,
  isVerified: false,
  isChanged: false,
  isUpdated: false,
  user: null,
  data: null,
  success: false,
  message: null,
  error: null,
};

const handlers = {
  // START LOADING
  IS_LOADING(state) {
    return {
      ...state,
      isLoading: true,
    };
  },
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, error } = action.payload;
    if (user !== undefined) {
      return {
        ...state,
        isAuthenticated,
        isInitialized: true,
        isVerified: false,
        user,
      };
    } else {
      //
      return {
        ...state,
        isInitialized: false,
        isAuthenticated: false,
        user: null,
        error,
      };
    }
  },
  REQUESTSUCCESS: (state, action) => {
    return {
      ...state,
      isRegistered: false,
      isAuthenticated: false,
      isVerified: false,
      isSent: false,
      user: null,
      error: null,
    };
  },
  RESET_PROCESS: (state, action) => {
    return {
      ...state,
      isUpdated: false,
      error: null,
    };
  },
  LOGIN: (state, action) => {
    const { user, error } = action.payload;
    //
    if (user !== undefined) {
      return {
        ...state,
        isRegistered: false,
        isLoading: false,
        isAuthenticated: true,
        user,
        error: null,
      };
    } else {
      //
      return {
        ...state,
        isRegistered: false,
        isLoading: false,
        isAuthenticated: false,
        user: null,
        error,
      };
    }
  },
  UPDATE_PROFILE: (state, action) => {
    const { user, error } = action.payload;
    if (user !== undefined) {
      return {
        ...state,
        isUpdated: true,
        user,
        error: null,
        isLoading: false,
      };
    } else {
      //
      return {
        ...state,
        isUpdated: false,
        error,
        isLoading: false,
      };
    }
  },
  CHANGE_PASSWORD: (state, action) => {
    const { user, error } = action.payload;
    if (user !== undefined) {
      return {
        ...state,
        isUpdated: true,
        user,
        error: null,
      };
    } else {
      //
      return {
        ...state,
        isUpdated: false,
        error,
      };
    }
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user, error } = action.payload;
    if (user !== undefined) {
      return {
        ...state,
        // isAuthenticated: true,
        isLoading: false,
        isAuthenticated: false,
        isRegistered: true,
        user: null,
      };
    } else {
      //
      return {
        ...state,
        isLoading: false,
        isRegistered: false,
        isAuthenticated: false,
        user: null,
        error,
      };
    }
  },
  VERIFYEMAIL: (state, action) => {
    const { error, success, message } = action.payload;
    if (success) {
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        isVerified: true,
        success,
        message,
        error: null,
      };
    } else {
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        isVerified: false,
        user: null,
        message: null,
        error,
      };
    }
  },
  VERIFYOTP: (state, action) => {
    const { error, success, message } = action.payload;
    if (success) {
      return {
        ...state,
        isAuthenticated: false,
        isVerified: true,
        success,
        message,
        error: null,
      };
    } else {
      return {
        ...state,
        isAuthenticated: false,
        isVerified: false,
        user: null,
        message: null,
        error,
      };
    }
  },
  RESENDOTP: (state, action) => {
    const { success, message } = action.payload;
    if (success) {
      return {
        ...state,
        isAuthenticated: false,
        success,
        message,
      };
    } else {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        message,
      };
    }
  },
  FORGOTPASSWORD: (state, action) => {
    const { success, message, error } = action.payload;
    if (success) {
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        isSent: true,
        success,
        message,
      };
    } else {
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        user: null,
        error,
      };
    }
  },
  GET_RESET_DATA: (state, action) => {
    const { success, message, data, error } = action.payload;
    if (success) {
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        data,
        success,
        message,
      };
    } else {
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        user: null,
        data: null,
        error,
      };
    }
  },
  RESET_PASSWORD: (state, action) => {
    const { success, error } = action.payload;
    if (success) {
      return {
        ...state,
        isLoading: false,
        isRegistered: false,
        isAuthenticated: false,
        isChanged: true,
        success,
        error: null,
      };
    } else {
      //
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        isChanged: false,
        error,
      };
    }
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  updateProfile: () => Promise.resolve(),
  updateFinancerProfile: () => Promise.resolve(),
  changePassword: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  financierRegistration: () => Promise.resolve(),
  verifyEmail: () => Promise.resolve(),
  verifyOtp: () => Promise.resolve(),
  resendOtp: () => Promise.resolve(),
  getResetToken: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get("/validateToken");
          //
          const { user } = response.data;
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        // console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  // const login = async (email, password) => {
  const pageRequest = () => {
    dispatch({
      type: "REQUESTSUCCESS",
      payload: {
        error: null,
      },
    });
  };

  // reset all alert msg
  const validateLogin = async () => {
    try {
      const accessToken = window.localStorage.getItem("accessToken");
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axios.get("/validateToken");

        const { user } = response.data;

        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: "INITIALIZE",
        payload: {
          isAuthenticated: false,
          user: null,
          error: err,
        },
      });
    }
  };
  // reset all alert msg
  const resetProcess = () => {
    dispatch({
      type: "RESET_PROCESS",
      payload: {
        error: null,
      },
    });
  };

  // const login = async (email, password) => {
  const login = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    dispatch({
      type: "IS_LOADING",
      payload: {
        isLoading: true,
      },
    });
    try {
      const response = await axios.post(
        "/login",
        // {
        //   email,
        //   password,
        // },
        formData,
        config
      );

      const { user } = response.data;
      //
      setSession(response.data.token);
      dispatch({
        type: "LOGIN",
        payload: {
          user,
        },
      });
    } catch (error) {
      //
      dispatch({
        type: "LOGIN",
        payload: {
          error: error,
        },
      });
    }
  };

  const updateProfile = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    dispatch({
      type: "IS_LOADING",
    });
    try {
      const response = await axios.put("/update-profile", formData, config);

      const { userData } = response.data;
      dispatch({
        type: "UPDATE_PROFILE",
        payload: {
          user: userData,
        },
      });
    } catch (error) {
      dispatch({
        type: "UPDATE_PROFILE",
        payload: {
          error: error,
        },
      });
    }
  };
  const updateFinancerProfile = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    dispatch({
      type: "IS_LOADING",
    });
    try {
      const response = await axios.put(
        "/update-financer-profile",
        formData,
        config
      );

      const { userData } = response.data;
      dispatch({
        type: "UPDATE_PROFILE",
        payload: {
          user: userData,
        },
      });
    } catch (error) {
      dispatch({
        type: "UPDATE_PROFILE",
        payload: {
          error: error,
        },
      });
    }
  };

  const changePassword = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    dispatch({
      type: "IS_LOADING",
    });
    try {
      const response = await axios.post("/change-password", formData, config);

      const { userData } = response.data;
      dispatch({
        type: "CHANGE_PASSWORD",
        payload: {
          user: userData,
        },
      });
    } catch (error) {
      dispatch({
        type: "CHANGE_PASSWORD",
        payload: {
          error: error,
        },
      });
    }
  };

  const register = async (formData) => {
    //
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    dispatch({
      type: "IS_LOADING",
    });

    try {
      const response = await axios.post("/register", formData, config);
      // const { accessToken, user } = response.data;
      const { user, otp } = response.data;
      if (otp !== undefined) {
        window.localStorage.setItem("otpData", JSON.stringify(otp));
      }
      dispatch({
        type: "REGISTER",
        payload: {
          user,
        },
      });
    } catch (error) {
      //
      dispatch({
        type: "REGISTER",
        payload: {
          error: error,
        },
      });
    }
  };

  const financierRegistration = async (formData) => {
    //
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    dispatch({
      type: "IS_LOADING",
    });

    try {
      const response = await axios.post(
        "/financier-register",
        formData,
        config
      );
      // const { accessToken, user } = response.data;
      const { user, otp } = response.data;
      if (otp !== undefined) {
        window.localStorage.setItem("otpData", JSON.stringify(otp));
      }
      dispatch({
        type: "REGISTER",
        payload: {
          user,
        },
      });
    } catch (error) {
      //
      dispatch({
        type: "REGISTER",
        payload: {
          error: error,
        },
      });
    }
  };

  const verifyEmail = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    dispatch({
      type: "IS_LOADING",
    });

    try {
      const response = await axios.post("/verify-email", formData, config);
      const { success, message } = response.data;
      if (success === true) {
        dispatch({
          type: "VERIFYEMAIL",
          payload: {
            success,
            message,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: "VERIFYEMAIL",
        payload: {
          error: error,
        },
      });
    }
  };

  const verifyOtp = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.post("/verify-otp", formData, config);
      const { success, message } = response.data;
      if (success === true) {
        // window.localStorage.removeItem("otpData");
        dispatch({
          type: "VERIFYOTP",
          payload: {
            success,
            message,
          },
        });
      }
    } catch (error) {
      //
      dispatch({
        type: "VERIFYOTP",
        payload: {
          error: error,
        },
      });
    }
  };

  const resendOtp = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.post("/resend-otp", formData, config);
      const { otp, success, message } = response.data;
      if (otp !== undefined) {
        window.localStorage.setItem("otpData", JSON.stringify(otp));
      }
      dispatch({
        type: "RESENDOTP",
        payload: {
          success,
          message,
        },
      });
    } catch (error) {
      //
      dispatch({
        type: "RESENDOTP",
        payload: {
          message: error.message,
        },
      });
    }
  };

  const logout = async () => {
    setSession(null);
    localStorage.clear();
    dispatch({ type: "LOGOUT" });
  };

  //forgot password
  const forgotPassword = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    dispatch({
      type: "IS_LOADING",
    });
    try {
      const response = await axios.post("/forgot-password", formData, config);

      const { success, message } = response.data;
      dispatch({
        type: "FORGOTPASSWORD",
        payload: {
          success,
          message,
        },
      });
    } catch (error) {
      //
      dispatch({
        type: "FORGOTPASSWORD",
        payload: {
          error: error,
        },
      });
    }
  };

  const getResetToken = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      dispatch({
        type: "IS_LOADING",
      });
      const response = await axios.post("/get-reset-data", formData, config);
      const { success, data } = response.data;
      // console.log("data", data)
      // window.localStorage.setItem('resetData', JSON.stringify(data));
      if (data !== undefined && data !== null) {
        window.localStorage.setItem("resetEmail", data.email);
      }
      dispatch({
        type: "GET_RESET_DATA",
        payload: {
          data,
          success,
        },
      });
    } catch (error) {
      dispatch({
        type: "GET_RESET_DATA",
        payload: {
          error: error,
        },
      });
    }
  };
  const resetPassword = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      dispatch({
        type: "IS_LOADING",
      });
      const response = await axios.post("/reset-password", formData, config);
      const { success } = response.data;
      // setSession(response.data.token);
      dispatch({
        type: "RESET_PASSWORD",
        payload: {
          success,
        },
      });
    } catch (error) {
      //
      dispatch({
        type: "RESET_PASSWORD",
        payload: {
          error: error,
        },
      });
    }
  };

  const value = useMemo(
    () => ({
      ...state,
      method: "jwt",
      validateLogin,
      pageRequest,
      resetProcess,
      login,
      updateProfile,
      updateFinancerProfile,
      changePassword,
      logout,
      register,
      financierRegistration,
      forgotPassword,
      getResetToken,
      resetPassword,
      verifyEmail,
      verifyOtp,
      resendOtp,
    }),
    [state]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export { AuthContext, AuthProvider };
