import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";

//Search thunk
export const keywordSearch = createAsyncThunk(
  "globalSearch/searchKeywordStatus",
  async (payload) => {
    const response = await axios.get(
      `/product-global-search?category=${payload.category}&keyword=${payload.keyword}`
    );
    return response.data;
  }
);

// Global Search Slice
const globalSearchSlice = createSlice({
  name: "globalSearch",
  initialState: {
    isGlobalSearchLoading: false,
    searchedItems: [],
  },
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isGlobalSearchLoading = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(keywordSearch.pending, (state) => {
      state.isGlobalSearchLoading = true;
      state.searchedItems = [];
    });
    builder.addCase(keywordSearch.fulfilled, (state, action) => {
      state.isGlobalSearchLoading = false;
      if (action.payload?.success === true) {
        state.searchedItems = action.payload.products;
      } else {
        state.searchedItems = [];
      }
    });
    builder.addCase(keywordSearch.rejected, (state) => {
      state.isGlobalSearchLoading = false;
      state.searchedItems = [];
    });
  },
});

const globalSearchReducer = globalSearchSlice.reducer;
export default globalSearchReducer;
