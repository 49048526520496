import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { dispatch } from "../store";


// Initial State

let initialState = {
    isLoading: false,
    error: false,
    reviews : null,
    isReviewAdded : false,
}

// Review slice 

const slice = createSlice({
    name : "review",
    initialState : initialState,
    reducers : {
    // START LOADING
      startLoading(state) {
        state.isLoading = true;
      },
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },
      // Reset Review add
      resetReviewAdd(state, action){
        state.isReviewAdded = false
      },

      // Review Add success
      reviewAddSuccess(state, action){
        state.isReviewAdded = true
      }
    },
    extraReducers : (builder)  => {
        // Adding review
        builder.addCase(addReview.pending, (state) => {
            state.isLoading = true;
          });
          builder.addCase(addReview.fulfilled, (state, action) => {
            state.isLoading = false;
            state.reviews = action.payload;
          });
          builder.addCase(addReview.rejected, (state, action) => {
            slice.actions.hasError(action.payload)
          });
    }
})

export default slice.reducer;

export const addReview = createAsyncThunk(
    //action type string
    "review/add-reviews",
    // callback function
    async (data) => { 
        const {product_id, rating_value, review} = data;
        let payload = {
            product_id,
            rating_value,
            review
        }
        // 
      const response = await axios.post("/customer-add-rating", payload);
      if (response.data.success === true) {
        dispatch(slice.actions.reviewAddSuccess())
      }
    //   
      return response.data;
    }
  );


  // Reset Add review success 
  export const ResetAddReview = () => {
    return async (dispatch) => {
      dispatch(slice.actions.resetReviewAdd());
    }
  } 