import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  loading: false,
  error: false,
  wishlist: null,
  wishlists: [],
  wishlistsForContext: [],
  wishlistsForContextCount: [],
  compareForContextData: [],
  wishlistProductIds: [],
  isWishlistDeleted: false,
  isWishlistAdded: false,
  wishlistAddMsg: "",
  wishlistDelMsg: "",
  isWishlistServerActiontatus: false,
};

export const getAllWishlists = createAsyncThunk(
  //action type string
  "wishlist/get-wishlists",
  // callback function
  async ({ pageNo }) => {
    const response = await axios.post(`/get-wishlists`, { page: pageNo });
    return response.data;
  }
);

export const addWishlist = createAsyncThunk(
  //action type string
  "wishlist/add-wishlist",
  // callback function
  async ({ product_id, variant_id }) => {
    const response = await axios.post(`/add-to-wishlist`, {
      product_id: product_id,
      variant_id: variant_id,
    });
    return response.data;
  }
);
export const deleteWishlist = createAsyncThunk(
  //action type string
  "wishlist/delete-wishlist",
  // callback function
  async ({ product_id, variant_id, wishlist_id }) => {
    const response = await axios.post(`/delete-wishlist`, {
      product_id: product_id,
      variant_id: variant_id,
      wishlist_id: wishlist_id,
    });
    return response.data;
  }
);

export const getAllWishlistsForContext = createAsyncThunk(
  //action type string
  "wishlist/get-wishlists-count-for-context",
  // callback function
  async () => {
    const response = await axios.post(`/get-wishlists-count`);
    return response.data;
  }
);

const slice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.isSaved = false;
    },
    // Reset LOADING
    requestReset(state) {
      state.isSaved = false;
      state.pageData = null;
      state.error = null;
      state.isLocationSaved = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.isSaved = false;
    },
    // GET COMPARE PRODUCTS
    getCompareProductIdsSuccess(state, action) {
      state.isLoading = false;
      state.compareForContextData = action.payload;
    },
  },
  extraReducers: (builder) => {
    //Get wishlists
    builder.addCase(getAllWishlists.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllWishlists.fulfilled, (state, action) => {
      state.loading = false;
      state.wishlists = action.payload;
    });
    builder.addCase(getAllWishlists.rejected, (state, action) => {
      state.loading = false;
    });
    //Get wishlists count
    builder.addCase(getAllWishlistsForContext.pending, (state) => {
      state.loading = true;
      state.wishlistAddMsg = "";
      state.isWishlistAdded = false;
      state.isWishlistServerActiontatus = false;
    });
    builder.addCase(getAllWishlistsForContext.fulfilled, (state, action) => {
      state.loading = false;
      state.wishlist = action.payload.wishlist;
      state.wishlistsForContextCount = action.payload?.wishlists;
      state.isWishlistServerActiontatus = action.payload.success;
      state.wishlistAddMsg = action.payload.message;
      state.isWishlistAdded = true;
    });
    builder.addCase(getAllWishlistsForContext.rejected, (state) => {
      state.loading = false;
      state.wishlistAddMsg = "";
      state.isWishlistAdded = false;
      state.isWishlistServerActiontatus = false;
    });
    //Add wishlist
    builder.addCase(addWishlist.pending, (state) => {
      state.loading = true;
      state.wishlistAddMsg = "";
      state.isWishlistAdded = false;
      state.isWishlistServerActiontatus = false;
    });
    builder.addCase(addWishlist.fulfilled, (state, action) => {
      state.loading = false;
      state.wishlist = action.payload.wishlist;
      state.isWishlistServerActiontatus = action.payload.success;
      state.wishlistAddMsg = action.payload.message;
      state.isWishlistAdded = true;
    });
    builder.addCase(addWishlist.rejected, (state) => {
      state.loading = false;
      state.wishlistAddMsg = "";
      state.isWishlistAdded = false;
      state.isWishlistServerActiontatus = false;
    });

    //Delete wishlist
    builder.addCase(deleteWishlist.pending, (state) => {
      state.loading = true;
      state.wishlistAddMsg = "";
      state.isWishlistAdded = false;
      state.isWishlistServerActiontatus = false;
    });
    builder.addCase(deleteWishlist.fulfilled, (state, action) => {
      state.loading = false;
      state.isWishlistServerActiontatus = action.payload.success;
      state.wishlistAddMsg = action.payload.message;
      state.isWishlistAdded = true;
    });
    builder.addCase(deleteWishlist.rejected, (state) => {
      state.loading = false;
      state.wishlistAddMsg = "";
      state.isWishlistAdded = false;
      state.isWishlistServerActiontatus = false;
    });
  },
});

// Reducer
export default slice.reducer;

//reinit
export function resetLoading() {
  return async (dispatch) => {
    dispatch(slice.actions.requestReset());
  };
}

//add product to compare
export const addToCompare = (product) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    dispatch(slice.actions.requestReset());
    dispatch(slice.actions.getCompareProductIdsSuccess(product));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};
