import { createContext, useEffect, useMemo, useReducer } from "react";
import PropTypes from "prop-types";

// ----------------------------------------------------------------------

const initialState = {
  cartId: "",
  cart_type: "",
  billingId: "",
  shippingId: "",
};

const handlers = {
  SAVE_DATA: (state, action) => {
    const { cart_id, cart_type, billingId, shippingId } = action.payload;
    console.log('action.payload', action.payload);
    return {
      ...state,
      cartId: cart_id,
      cart_type,
      billingId,
      shippingId,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const PlaceOrderContext = createContext({
  ...initialState,
  savePlaceOrderData: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

PlaceOrderProvider.propTypes = {
  children: PropTypes.node,
};

function PlaceOrderProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    console.log("place order context");
  }, []);

  const savePlaceOrderData = async (placeOrderData) => {
    dispatch({
      type: "SAVE_DATA",
      payload: {
        ...placeOrderData,
      },
    });
  };

  const value = useMemo(
    () => ({
      ...state,
      savePlaceOrderData,
    }),
    [state]
  );
  return (
    <PlaceOrderContext.Provider value={value}>
      {children}
    </PlaceOrderContext.Provider>
  );
}

export { PlaceOrderContext, PlaceOrderProvider };
