import React from "react";

function LoadingScreen() {
  return (
    <>
      <div className="loader_wrapper d-flex justify-content-center mt-4">
        <img
          width="200px"
          src={process.env.PUBLIC_URL + "/images/loader.gif"}
          alt="loader"
        />
      </div>
    </>
  );
}

export default LoadingScreen