import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  loanDetails: null,
  loanOffers: [],
  offerMsgs: [],
  conversations: [],
  isAgreed: null,
  isFinancerAgreed: null,
};

const loanOffers = createSlice({
  name: "loanOffers",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.isSaved = false;
      state.prevSaved = false;
      state.gstVerified = false;
      state.panVerified = false;
      state.isAgreed = null;
      state.isFinancerAgreed = null;
      state.loanDetails = null;
    },
    // Reset LOADING
    requestReset(state) {
      state.isLoading = false;
      state.isSaved = false;
      state.gstVerified = false;
      state.panVerified = false;
      state.isAgreed = null;
      state.isFinancerAgreed = null;
      state.loanDetails = null;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.isSaved = false;
      state.error = action.payload;
      state.gstVerified = false;
      state.panVerified = false;
      state.isAgreed = null;
      state.isFinancerAgreed = null;
      state.loanDetails = null;
      // state.isSaved = false;
    },
  },
  extraReducers: (builder) => {
    // get loan offers
    builder.addCase(getLoanOffers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getLoanOffers.fulfilled, (state, action) => {
      state.loanOffers = action.payload?.loanOffers;
      state.isLoading = false;
    });
    builder.addCase(getLoanOffers.rejected, (state) => {
      state.isLoading = false;
    });
    // get customer loan offer
    builder.addCase(getCustomerLoanOffer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCustomerLoanOffer.fulfilled, (state, action) => {
      state.loanOffers = action.payload?.loanOffers;
      state.isLoading = false;
    });
    builder.addCase(getCustomerLoanOffer.rejected, (state) => {
      state.isLoading = false;
    });
    // get loan offer message
    builder.addCase(getLoanOfferMsg.pending, (state) => {
      state.isLoading = true;
      state.loanDetails = null;
    });
    builder.addCase(getLoanOfferMsg.fulfilled, (state, action) => {
      state.offerMsgs = action.payload?.offerMsgs;
      state.loanDetails = action.payload?.offerDetails;
      state.isLoading = false;
    });
    builder.addCase(getLoanOfferMsg.rejected, (state) => {
      state.isLoading = false;
      state.loanDetails = null;
    });

    builder.addCase(acceptLoanOffer.pending, (state) => {
      state.isAgreed = null;
      state.isLoading = true;
    });
    builder.addCase(acceptLoanOffer.fulfilled, (state, action) => {
      state.isAgreed = action.payload?.data;
      state.isLoading = false;
    });
    builder.addCase(acceptLoanOffer.rejected, (state, action) => {
      state.isAgreed = action.payload;
      state.isLoading = false;
    });

    // builder.addCase(acceptFromFinancer.pending, (state) => {
    //   state.isFinancerAgreed = null;
    //   state.isLoading = true;
    // });
    // builder.addCase(acceptFromFinancer.fulfilled, (state, action) => {
    //   state.isFinancerAgreed = action.payload?.data;
    //   state.isLoading = false;
    // });
    // builder.addCase(acceptFromFinancer.rejected, (state, action) => {
    //   state.isFinancerAgreed = action.payload;
    //   state.isLoading = false;
    // });

    builder.addCase(getMessages.pending, (state) => {
      state.isLoading = true;
      state.loanDetails = null;
    });
    builder.addCase(getMessages.fulfilled, (state, action) => {
      state.conversations = action.payload?.offerMessages;
      state.isLoading = false;
    });
    builder.addCase(getMessages.rejected, (state) => {
      state.isLoading = false;
      state.conversations = null;
    });
  },
});

// Reducer
export default loanOffers.reducer;

// Actions
// export const { savecustomerBasicInfoSuccess } = slice.actions;

// ----------------------------------------------------------------------
//reinit
export function resetLoading() {
  return async (dispatch) => {
    dispatch(loanOffers.actions.requestReset());
  };
}

/**
 * Get loan document kyc
 */
export const getLoanOffers = createAsyncThunk(
  //action type string
  "loan/get-loan-offers",
  // callback function
  async (id) => {
    const response = await axios.get(`list-loan-offers/${id}`);
    return response.data;
  }
);

/**
 * Get latest loan offer
 */
export const getCustomerLoanOffer = createAsyncThunk(
  //action type string
  "list-loan-offers",
  // callback function
  async (id) => {
    const response = await axios.get(`list-loan-offers/${id}`);
    return response.data;
  }
);

/**
 * Get latest loan offer
 */
export const getLoanOfferMsg = createAsyncThunk(
  //action type string
  "loan/get-financer-offers",
  // callback function
  async (offerId) => {
    const response = await axios.get(`get-financer-offers/${offerId}`);
    // const response = await axios.get(`get-offer-messages/${offerId}`);
    return response.data;
  }
);

export const rejectLoanOffer = createAsyncThunk(
  //action type string
  "loan/reject-loan-offer",
  // callback function
  async (offerId) => {
    const response = await axios.get(`reject-loan-offer/${offerId}`);
    return response.data;
  }
);
export const acceptLoanOffer = createAsyncThunk(
  "accept-loan-offer",
  async (data) => {
    const response = await axios.post(`/accept-loan-offer`, data);
    return response.data;
  }
);

// export const acceptFromFinancer = createAsyncThunk(
//   "accept-loan-offer",
//   async (data) => {
//     const response = await axios.post(`/accept-loan-offer`, data);
//     return response.data;
//   }
// );

export const sendLoanOffer = createAsyncThunk(
  //action type string
  "customer/send-loan-offer",
  // callback function
  async ({ offerId, message, sendOffer }) => {
    var params = new URLSearchParams();
    // params.append("message", message.message);
    if (sendOffer) {
      params.append("tenure", message.tenure);
      params.append("roi", message.roi);
      params.append("amount", message.amount);
    }
    const response = await axios.post(`send-loan-message/${offerId}`, params, {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    });
    return response.data;
  }
);

export const sendMessage = createAsyncThunk(
  //action type string
  "customer/loan/send-message",
  // callback function
  async ({ loanId, message }) => {
    // var params = new URLSearchParams();
    // params.append("message", message);
    // if (sendOffer) {
    //   params.append("tenure", message.tenure);
    //   params.append("roi", message.roi);
    //   params.append("amount", message.amount);
    // }
    // const response = await axios.post(`loan/send-message/${offerId}`, params, {
    //   headers: { "content-type": "application/x-www-form-urlencoded" },
    // });
    const response = await axios.post("loan/send-message", {
      loanOfferId: loanId,
      message: message,
    });
    return response.data;
  }
);

// Get normal messages
export const getMessages = createAsyncThunk(
  //action type string
  "loan/get-messages/",
  // callback function
  async (offerId) => {
    const response = await axios.get(`loan/get-messages/${offerId}`);
    return response.data;
  }
);
