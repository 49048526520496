import React, { useState, createContext, useEffect } from "react";

import { useDispatch, useSelector } from "./../redux/store";

import { getAllWishlistsForContext } from "./../redux/slices/wishlist";
import useAuth from "../hooks/useAuth";

export const WishListContext = createContext();

export const WishListProvider = (props) => {
  let dispatch = useDispatch();
  const {user} = useAuth()
  const { wishlistsForContextCount } = useSelector((state) => state.wishlist);
  const [wishlistsForContext, setWishlistsForContext] = useState([]);

  useEffect(() => {
        dispatch(getAllWishlistsForContext());
        setWishlistsForContext(wishlistsForContextCount);    
    //
  }, [wishlistsForContext]);

  return (
    <WishListContext.Provider
      value={[wishlistsForContextCount, setWishlistsForContext]}
    >
      {props.children}
    </WishListContext.Provider>
  );
};
