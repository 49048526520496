import React, { useContext } from "react";
import {
  Button,
  FormControl,
  InputGroup,
  Offcanvas,
  OffcanvasBody,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { WishListContext } from "../../../contexts/WishListContext";
import { deleteWishlist } from "./../../../redux/slices/wishlist";
import OffWishlistCanvasCard from "../OffCanvasCard/OffWishlistCanvasCard";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { useClickOutside } from "../../../utils/helpers";

export const WishListShow = ({
  handleCloseWishList,
  placement,
  showWishList,
}) => {
  let dispatch = useDispatch();
  const navigate  = useNavigate()
  const [wishlistsForContext, setWishlistsForContext] =
    useContext(WishListContext);

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        cancelButton: "btn btn-danger mx-2",
        confirmButton: "btn btn-primary mx-2", 
      },
      buttonsStyling: false,
    });


  const removeItem = (product_id, variant_id, wishlist_id) => {


    swalWithBootstrapButtons.fire({
      icon : "question",
      text : "Are you sure?",
      showCancelButton: true,
      confirmButtonText: 'delete',
      reverseButtons : true
    })
    .then(result => {
      if (result.isConfirmed) {
        dispatch(deleteWishlist({ product_id, variant_id, wishlist_id }))
      .unwrap()
      .then((res) => {
        
        if (res.success === true) {
          const removeThenCartItem = wishlistsForContext.filter(
            (listItem) =>
              listItem?.product_id !== product_id &&
              listItem?.variant_id !== variant_id
          );
          setWishlistsForContext([...removeThenCartItem]);
          Swal.fire({
            icon: "success",
            text: `${res.message}`,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: `${res.message}`,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          text: `${err.message}`,
        });
      });
      }
      else{
        return
      }
    })

    
  };

  let domNode = useClickOutside(() => {
    handleCloseWishList(false);
  });

  const handleProceedToWishlist = () => {
    handleCloseWishList(false);
    navigate("/user/wishlist");
  }

  return (
    <Offcanvas
      className={"wishListCanvas"}
      show={showWishList}
      onHide={handleCloseWishList}
      placement={placement}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="text-primary textwarning">
        <i className="fas fa-heart me-2"></i>
          Wishlist (
          {wishlistsForContext?.length ? wishlistsForContext?.length : 0})
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body ref={domNode} className="d-flex flex-column ">
        {wishlistsForContext?.length && wishlistsForContext?.length
          ? wishlistsForContext.map((item, index) =>
              item ? (
                <OffWishlistCanvasCard
                  item={item}
                  removeItem={removeItem}
                  key={`${item?.id} + ${item?.variant_id}`}
                />
              ) : (
                ""
              )
            )
          : ""}

        {wishlistsForContext?.length <= 0 ? (
          <h6 className="text-center">No item in the wishlist</h6>
        ) : (
          ""
        )}
        <div className="cart-footer" style={{width : "100%", left : "0"}}>
        <button disabled={wishlistsForContext?.length === 0} style={{opacity : wishlistsForContext?.length === 0 ? "0.6" : "1"}} className="cart-checkout-btn d-flex align-items-center w-100" onClick={handleProceedToWishlist}>
                  <span className="checkout-label"> View Wishlists</span>
          </button>
          </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
