import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { dispatch } from "../store";


// Initial State

let initialState = {
    isLoading: false,
    error: false,
    dashboardOrders : []
}

// Order slice 
const OrderSlice = createSlice({
  name: "order",
  initialState: initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Order success
    dashboardOrderDetailsSuccess(state, action) {
      state.isLoading = true;
      //console.log('dashboardOrders => action', action.payload.orderData);
      state.dashboardOrders = action.payload.orderData;
    },
  },
  extraReducers: (builder) => {
    // Getting oorder
    builder.addCase(getDashboardOrderDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getDashboardOrderDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.dashboardOrders = action.payload.orderData;
      //console.log('dashboardOrders action', action.payload.orderData);
    });
    builder.addCase(getDashboardOrderDetails.rejected, (state, action) => {
      OrderSlice.actions.hasError(action.payload);
      state.isLoading = false;
    });
  }
});

const orderSlice = OrderSlice.reducer;
export default orderSlice;

export const getDashboardOrderDetails = createAsyncThunk(
    //action type string
    "/dashboarOrder/get-orders-for-graph",
    // callback function
    async (year) => { 
      const response = await axios.post("/get-orders-for-graph", {year:year});
      // console.log('dashboardOrders response', response.data.success); 
      return response.data;
    }
);

/*
export const getDashboardOrderDetails = () => async (dispatch) => {
    dispatch(OrderSlice.actions.startLoading());
    try {
      const response = await axios.post("/get-orders-for-graph");
      //console.log('dashboardOrders response', response.data.success);
      if (response.data.success === true) {
        dispatch(OrderSlice.actions.dashboardOrderDetailsSuccess(response.data))
      } 
    } catch (error) {
      // 
      dispatch(OrderSlice.actions.hasError(error));
    }
  };*/
  
