import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
// utils
import axios from "../../utils/axios";

// CustomerOrder Slice
const customerOrdersSlice = createSlice({
  name: "customerOrders",
  initialState: {
    isLoading: false,
    order: null,
    orders: [],
    isEmpty: false,
  },
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // Reset LOADING
    requestReset(state) {
      state.order = null;
      state.error = null;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // LIST INVENTORIES
    listCustomerOrdersSuccess(state, action) {
      state.isLoading = false;
      state.orders = action.payload;
      if (
        action.payload === "" ||
        action.payload === undefined ||
        action.payload === null
      ) {
        state.isEmpty = true;
        state.orders = null;
      }
    },
    // Order Detail
    getCustomerOrderSuccess(state, action) {
      state.isLoading = false;
      state.order = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getCustomerOrder.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getCustomerOrder.fulfilled, (state, action) => {
      state.isLoading = false;
      state.order = action.payload.order;
    });

    builder.addCase(getCustomerOrder.rejected, (state) => {
      state.isLoading = false;
    });
    // lIST ORDERS
    // builder.addCase(listCustomerOrders.pending, (state) => {
    //   state.isLoading = true;
    // })
    // builder.addCase(listCustomerOrders.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   state.orders = action.payload;
    //   state.order = null;
    // })
    // builder.addCase(listCustomerOrders.rejected), (state) => {
    //   state.isLoading = false;
    // }
  },
});

const customerOrdersReducer = customerOrdersSlice.reducer;
export default customerOrdersReducer;

//Actions
//reinit
export function resetLoading() {
  return async (dispatch) => {
    dispatch(customerOrdersSlice.actions.requestReset());
  };
}

// list customerOrders
export const listCustomerOrders = () => async (dispatch) => {
  //  return;
  dispatch(customerOrdersSlice.actions.startLoading());
  try {
    const response = await axios.post("/customer/orders");
    console.log("---response---", response);
    dispatch(
      customerOrdersSlice.actions.listCustomerOrdersSuccess(
        response.data.orders.data
      )
    );
  } catch (error) {
    //
    dispatch(customerOrdersSlice.actions.hasError(error));
  }
};
//list customerOrders
// export const listCustomerOrders = createAsyncThunk(
//   "customerOrders/list-orders",
//   async () => {
//     const response = await axios.post("/customer/orders");
//     return response.data;
//   }
// )

//get customerOrder
export const getCustomerOrder = createAsyncThunk(
  //action type string
  "/customer/order-detail",
  // callback function
  async (customerOrderData) => {
    const response = await axios.post(
      "/customer/order-detail",
      customerOrderData
    );
    return response.data;
  }
);
