import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
// Alert
import Swal from "sweetalert2";
import { dispatch } from "../store";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";

// Inventory Slice
const quotesSlice = createSlice({
  name: "quotes",
  initialState: {
    isLoading: false,
    quote: null,
    requestedQuotes: [],
    quotes: [],
    suppliers: [],
    quoteCount: null,
    offer: null,
    quoteAdded: false,
    quoteDeleted: false,
    quoteSubmitted: false,
    quoteUpdated: false,
    quoteSelected: false,
    supplierLoading: false,
    attachmentDeleted : false,
    attachmentDeleteLoading : false,
    attachmentLoading : false,
    tempAttachments : []
  },
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.quoteAdded = false;
      state.quoteDeleted = false;
      state.quoteSubmitted = false;
      state.quoteUpdated = false;
    },
    suppliersLoading(state) {
      state.supplierLoading = true;
    },
    // Reset LOADING
    requestReset(state) {
      state.quoteAdded = false;
      state.quoteUpdated = false;
      state.attachmentDeleted = false;
      state.error = null;
      state.quoteSubmitted = false;
      state.quoteSelected = false;
    },

    attachmentReset(state) {
      state.attachmentDeleted = false;
      state.attachmentLoading = false;
    },
    cleanQuotes(state) {
      state.quotes = null;
      state.quote = null;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.quoteAdded = false;
    },
    // REQUEST A QUOTE
    requestQuoteSuccess(state, action) {
      state.isLoading = false;
      state.quote = action.payload;
      state.quoteAdded = true;
    },
    // LIST REQUESTED QUOTES
    listRequestedQuotesSuccess(state, action) {
      state.isLoading = false;
      // state.quoteDeleted = false;
      state.requestedQuotes = action.payload;
    },
    // UPDATE REQUESTED QUOTE
    updateRequestedQuoteSuccess(state, action) {
      state.isLoading = false;
      state.quoteUpdated = true;
      state.requestedQuotes = action.payload;
    },
    // DELETE REQUESTED QUOTES
    deleteRequestedQuoteSuccess(state, action) {
      state.isLoading = false;
      state.quoteDeleted = true;
    },
    // DELETE QUOTES
    deleteQuoteSuccess(state, action) {
      state.isLoading = false;
      state.quoteDeleted = true;
    },

    // ADD MY QUOTE
    addMyQuoteSuccess(state, action) {
      state.isLoading = true;
      state.quoteSubmitted = true;
    },
    // LIST CUSTOMER QUOTES
    listCustomerQuotesSuccess(state, action) {
      state.isLoading = false;
      state.quotes = action.payload.sort((a, b) =>
        new Date(a.created_at) > new Date(b.created_at) ? -1 : 1
      );
      state.quoteCount = action.payload.length;
      state.quoteSubmitted = false;
    },
    // GET CUSTOMER QUOTE
    getCustomerQuoteSuccess(state, action) {
      state.isLoading = false;
      state.quote = action.payload;
    },
    // SELECT QUOTE
    selectQuoteSuccess(state, action) {
      state.isLoading = false;
      state.offer = action.payload;
      state.quoteSelected = true;
    },
    // LIST MATCHING SUPPLIERS
    listSuppliersForOffersSuccess(state, action) {
      state.supplierLoading = false;
      state.suppliers = action.payload;
    },

  },

  extraReducers : (builder) => {
    builder.addCase(selectQuoteThunk.pending, (state) => {
      state.isLoading = true;
      state.quoteAdded = false;
      state.quoteDeleted = false;
      state.quoteSubmitted = false;
      state.quoteUpdated = false;
    });
    builder.addCase(selectQuoteThunk.fulfilled, (state, action) => {
      state.isLoading = false;
      state.offer = action.payload;
      state.quoteSelected = true;
    });
    builder.addCase(selectQuoteThunk.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.quoteAdded = false;
    });
    builder.addCase(getRequestedQuote.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getRequestedQuote.fulfilled, (state, action) => {
      state.isLoading = false;
      state.quote = action.payload;
    });
    builder.addCase(getRequestedQuote.rejected, (state, action) => {
      state.isLoading = false;
      state.quote = action.payload;
    });

    builder.addCase(updateRequestedQuote.pending, (state) => {
      state.isLoading = true;
      state.quoteAdded = false;
      state.quoteDeleted = false;
      state.quoteSubmitted = false;
      state.quoteUpdated = false;
    });
    builder.addCase(updateRequestedQuote.fulfilled, (state, action) => {
      state.isLoading = false;
      state.quoteUpdated = true;
      state.requestedQuotes = action.payload;
    });
    builder.addCase(updateRequestedQuote.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.quoteAdded = false;
    });

    // Attachment Builders
    builder.addCase(deleteAttachment.pending, (state, action) => {
      state.attachmentDeleteLoading = true; 
    });
    builder.addCase(deleteAttachment.fulfilled, (state, action) => {
      state.attachmentDeleted = true;
      state.attachmentDeleteLoading = false;
      state.tempAttachments = []

    });
    builder.addCase(deleteAttachment.rejected, (state, action) => {
      state.attachmentDeleted = false;
      state.attachmentDeleteLoading = false; 

    });
    // Attachment Builders
    builder.addCase(getAllTempAttachments.pending, (state, action) => {
      state.attachmentLoading = true;
      state.tempAttachments = []
    });
    builder.addCase(getAllTempAttachments.fulfilled, (state, action) => {
      console.log({action: action});
      state.tempAttachments = action.payload;
      state.attachmentLoading = false;
    });
    builder.addCase(getAllTempAttachments.rejected, (state, action) => {
      state.attachmentLoading = false;
    });



  }
});

const quotesReducer = quotesSlice.reducer;
export default quotesReducer;

//Actions
//reinit
export function resetLoading() {
  return async (dispatch) => {
    dispatch(quotesSlice.actions.requestReset());
  };
}
export function resetAttachmentLoading() {
  return async (dispatch) => {
    dispatch(quotesSlice.actions.attachmentReset());
  };
}

export function quoteCleanup(state) {
  return async (dispatch) => {
    dispatch(quotesSlice.actions.cleanQuotes());
  };
}

//request a quote
export const requestQuote = (quoteData) => async (dispatch) => {
  dispatch(quotesSlice.actions.startLoading());
  try {
    const response = await axios.post("/request-a-quote", quoteData, {
      headers: {
        // "Content-Type": "multipart/form-data",
        "Content-Type": "application/json",
      },
    });
    dispatch(quotesSlice.actions.requestQuoteSuccess(response.data.quote));
  } catch (error) {
    dispatch(quotesSlice.actions.hasError(error));
  }
};
//requested quotes
export const listRequestedQuotes = () => async (dispatch) => {
  //  return;
  dispatch(quotesSlice.actions.startLoading());
  try {
    const response = await axios.post("/list-requested-quotes");
    dispatch(
      quotesSlice.actions.listRequestedQuotesSuccess(response.data.quotes)
    );
  } catch (error) {
    // 
    dispatch(quotesSlice.actions.hasError(error));
  }
};

// update requested a quote
// export const updateRequestedQuote = (quoteData) => async (dispatch) => {
//   dispatch(quotesSlice.actions.startLoading());
//   try {
//     const response = await axios.post("/update-temp-quote-item", quoteData);
//     dispatch(
//       quotesSlice.actions.updateRequestedQuoteSuccess(response.data.quote)
//     );
//   } catch (error) {
//     dispatch(quotesSlice.actions.hasError(error));
//   }
// };

export const updateRequestedQuote = createAsyncThunk(
  //action type string
  "quotes/update-quote",
  // callback function
  async (quoteData) => {
    const response = await axios.post("/update-temp-quote-item", quoteData);
    return response.data;
  }
);

//deleted Requested quotes
export const deleteRequestedQuote = (id) => async (dispatch) => {
  //  return;
  dispatch(quotesSlice.actions.startLoading());
  try {
    const response = await axios.post("/delete-requested-quotes", { id });
    dispatch(quotesSlice.actions.deleteRequestedQuoteSuccess(response.data));
  } catch (error) {
    // 
    dispatch(quotesSlice.actions.hasError(error));
  }
};

//deleted quotes
export const deleteQuote = (id) => async (dispatch) => {
  //  return;
  dispatch(quotesSlice.actions.startLoading());
  try {
    const response = await axios.post("/delete-customer-quote", { id });
    dispatch(quotesSlice.actions.deleteQuoteSuccess(response.data));
  } catch (error) {
    dispatch(quotesSlice.actions.hasError(error));
  }
};

//add my quotes
export const addMyQuotes = (quoteData) => async (dispatch) => {
  dispatch(quotesSlice.actions.startLoading());
  try {
    const response = await axios.post("/add-product-to-quote", {
      ids: quoteData,
    });
    dispatch(quotesSlice.actions.addMyQuoteSuccess(response.data));
  } catch (error) {
    dispatch(quotesSlice.actions.hasError(error));
  }
};

//list quotes
export const listCustomerQuotes = () => async (dispatch) => {
  //  return;
  dispatch(quotesSlice.actions.startLoading());
  try {
    const response = await axios.post("/list-customer-quotes");
    dispatch(
      quotesSlice.actions.listCustomerQuotesSuccess(response.data.quotes)
    );
  } catch (error) {
    // 
    dispatch(quotesSlice.actions.hasError(error));
  }
};

//update requested a quote
export const getCustomerQuote = (quoteId, enableLoader) => async (dispatch) => {
  if (enableLoader) {
    dispatch(quotesSlice.actions.startLoading());
  }
  try {
    const response = await axios.post(
      "/view-customer-quotes-detail",
      { id: quoteId },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    dispatch(quotesSlice.actions.getCustomerQuoteSuccess(response.data.quote));
  } catch (error) {
    dispatch(quotesSlice.actions.hasError(error));
  }
};

//list suppliers based on Locations
export const listSuppliersForOffers =
  (quoteId, slug, customer_id, supplierFilter) => async (dispatch) => {
    //  return;
    dispatch(quotesSlice.actions.suppliersLoading());
    try {
      const response = await axios.post(
        "/get-matching-suppliers",
        {
          quote_id: quoteId,
          slug,
          customer_id,
        },
        { params: { ...supplierFilter } }
      );
      dispatch(
        quotesSlice.actions.listSuppliersForOffersSuccess(
          response.data.suppliers
        )
      );
    } catch (error) {
      // 
      dispatch(quotesSlice.actions.hasError(error));
    }
  };

//select a quote
export const selectQuote =
  (quoteId, supplierId, variantId) => async (dispatch) => {
    dispatch(quotesSlice.actions.startLoading());
    try {
      const response = await axios.post("/select-quote", {
        quote_id: quoteId,
        supplier_id: supplierId,
        variant_id: variantId,
      });
      dispatch(quotesSlice.actions.selectQuoteSuccess(response.data.offer));
    } catch (error) {
      dispatch(quotesSlice.actions.hasError(error));
    }
  };

export const selectQuoteThunk = createAsyncThunk(
  //action type string
  "quotes/select-quote",
  // callback function
  async ({ quoteId, supplierId, variantId }) => {
    const response = await axios.post("/select-quote", {
      quote_id: quoteId,
      supplier_id: supplierId,
      variant_id: variantId,
    });
    return response.data;
  }
);

export const getRequestedQuote = createAsyncThunk(
  //action type string
  "quotes/get-requested-quote",
  // callback function
  async ({ quoteId }) => {
    const response = await axios.get(`/get-requested-quote/${quoteId}`);
    return response.data.quote;
  }
);

export const deleteAttachment = createAsyncThunk(
  //action type string
  "quotes/delete-attachment",
  // callback function
  async (payload) => {
    const response = await axios.post('/delete-quote-attachment', payload);
    return response.data;
  }
);

export const getAllTempAttachments = createAsyncThunk(
  //action type string
  "quotes/get-temp-attachments",
  // callback function
  async (id) => {
    const response = await axios.get(`/get-temp-quote-attachments/${id}`);
    return response.data.data;
  }
);



