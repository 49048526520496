import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";

//Search thunk
export const contactWithSales = createAsyncThunk(
  "inquiry/contactWithSalesStatus",
  async (payload) => {
    const response = await axios.post(`/sales-inquiry`, payload);
    return response.data;
  }
);

// Inquiry Slice
const inquirySlice = createSlice({
  name: "inquiry",
  initialState: {
    isInquiryLoading: false,
  },
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isInquiryLoading = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(contactWithSales.pending, (state) => {
      state.isInquiryLoading = true;
    });
    builder.addCase(contactWithSales.fulfilled, (state, action) => {
      state.isInquiryLoading = false;
    });
    builder.addCase(contactWithSales.rejected, (state) => {
      state.isInquiryLoading = false;
    });
  },
});

const inquiryReducer = inquirySlice.reducer;
export default inquiryReducer;
