import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
// Alert
import Swal from "sweetalert2";
import { dispatch } from "../store";

// Product Slice
const supplierProductsSlice = createSlice({
  name: "supplierProducts",
  initialState: {
    isLoading: false,
    product: null,
    variant: null,
    variants: [],
    variantAdded: false,
    imageSaved: false,
    inventorySaved: false,
    products: [],
    productAdded: false,
    productUpdated: false,
    variantUpdated: false,
    productDeleted: false,
    variations: null,
  },
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.productAdded = false;
      state.productDeleted = false;
    },
    // Reset LOADING
    requestReset(state) {
      state.productAdded = false;
      state.productUpdated = false;
      state.productDeleted = false;
      state.product = null;
      state.variant = null;
      state.error = null;
    },
    // Reset LOADING
    requestProductReset(state) {
      state.productAdded = false;
      state.productUpdated = false;
      state.variantAdded = false;
      state.imageSaved = false;
      state.inventorySaved = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.productAdded = false;
    },
    // ADD PRODUCT
    addProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
      state.productAdded = true;
    },
    // LIST PRODUCTS
    listProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
      state.product = null;
      state.productAdded = false;
      state.productUpdated = false;
      state.variantAdded = false;
      state.imageSaved = false;
      state.inventorySaved = false;
    },
    cleanupProducts(state) {
      state.products = null;
    },
    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
      state.productAdded = false;
      state.productUpdated = false;
      state.variantAdded = false;
      state.imageSaved = false;
      state.inventorySaved = false;
    },
    // GET PRODUCT DYNAMIC DESCRIPTION
    getProductDynamicDescriptionSuccess(state, action) {
      state.isLoading = false;
      state.productDynamicDesc = action.payload;
      state.productAdded = false;
    },
    // GET PRODUCT DYNAMIC BULLET DESCRIPTION
    getProductDynamicBulletDescriptionSuccess(state, action) {
      state.isLoading = false;
      state.productDynamicBulletDesc = action.payload;
      state.productAdded = false;
    },
    getProductVariantSuccess(state, action) {
      state.isLoading = false;
      state.variant = action.payload;
      state.variantUpdated = false;
    },
    // UPDATE VARIANT
    updateVariantSuccess(state, action) {
      state.isLoading = false;
      state.variant = action.payload;
      state.variantUpdated = true;
    },
    // EDIT PRODUCT
    updateProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
      state.productUpdated = true;
    },
    // DELETE PRODUCT
    deleteProductSuccess(state, action) {
      state.isLoading = false;
      state.product = null;
      state.productDeleted = true;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAttributeOptions.pending, (state) => {
      state.isLoading = true;
      state.variantAdded = false;
    });

    builder.addCase(getAttributeOptions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.variations = action.payload;
      state.variantAdded = false;
      state.productAdded = false;
      state.productUpdated = false;
      state.imageSaved = false;
      state.inventorySaved = false;
    });

    builder.addCase(getAttributeOptions.rejected, (state) => {
      state.isLoading = false;
    });
    // add product basic
    builder.addCase(addProductBasic.pending, (state) => {
      state.isLoading = true;
      state.productAdded = false;
      state.productUpdated = false;
      state.variantAdded = false;
      state.imageSaved = false;
      state.inventorySaved = false;
    });
    builder.addCase(addProductBasic.fulfilled, (state, action) => {
      state.isLoading = false;
      state.product = action.payload.product;
      state.productAdded = true;
    });
    builder.addCase(addProductBasic.rejected, (state) => {
      state.isLoading = false;
      state.productAdded = false;
    });
    // save product variant
    builder.addCase(saveProductVariants.pending, (state) => {
      state.isLoading = true;
      state.variantAdded = false;
      state.productAdded = false;
      state.productUpdated = false;
      state.imageSaved = false;
      state.inventorySaved = false;
    });
    builder.addCase(saveProductVariants.fulfilled, (state, action) => {
      state.isLoading = false;
      state.variants = action.payload.product;
      state.variantAdded = true;
      // reset
      state.productAdded = false;
      state.productUpdated = false;
      state.imageSaved = false;
      state.inventorySaved = false;
    });
    builder.addCase(saveProductVariants.rejected, (state) => {
      state.isLoading = false;
      state.variantAdded = false;
    });
    // save product images
    builder.addCase(saveProductImages.pending, (state) => {
      state.isLoading = true;
      state.imageSaved = false;
      // reset 
      state.productAdded = false;
      state.productUpdated = false;
      state.variantAdded = false;
      state.inventorySaved = false;
    });
    builder.addCase(saveProductImages.fulfilled, (state, action) => {
      state.isLoading = false;
      state.variants = action.payload.product;
      state.imageSaved = true;
      // reset
      state.productAdded = false;
      state.productUpdated = false;
      state.variantAdded = false;
      state.inventorySaved = false;
    });
    builder.addCase(saveProductImages.rejected, (state) => {
      state.isLoading = false;
      state.imageSaved = false;
    });
    builder.addCase(saveProductInventories.pending, (state) => {
      state.isLoading = true;
      state.inventorySaved = false;
      // reset
      state.productAdded = false;
      state.productUpdated = false;
      state.variantAdded = false;
      state.imageSaved = false;
    });
    builder.addCase(saveProductInventories.fulfilled, (state, action) => {
      state.isLoading = false;
      state.inventorySaved = true;
      // reset
      state.productAdded = false;
      state.productUpdated = false;
      state.variantAdded = false;
      state.imageSaved = false;
    });
    builder.addCase(saveProductInventories.rejected, (state) => {
      state.isLoading = false;
      state.inventorySaved = false;
      // reset
      state.productAdded = false;
      state.productUpdated = false;
      state.variantAdded = false;
      state.imageSaved = false;
    });

    // update product basic
    builder.addCase(updateProductBasic.pending, (state) => {
      state.isLoading = true;
      state.productUpdated = false;
      // reset
      state.productAdded = false;
      state.variantAdded = false;
      state.imageSaved = false;
      state.inventorySaved = false;
    });
    builder.addCase(updateProductBasic.fulfilled, (state, action) => {
      state.isLoading = false;
      // state.product = action.payload.product;
      state.productUpdated = true;
      // reset
      state.productAdded = false;
      state.variantAdded = false;
      state.imageSaved = false;
      state.inventorySaved = false;
    });
    builder.addCase(updateProductBasic.rejected, (state) => {
      state.isLoading = false;
      state.productUpdated = false;
      // reset
      state.productAdded = false;
      state.variantAdded = false;
      state.imageSaved = false;
      state.inventorySaved = false;
    });
  },
});

const supplierProductsReducer = supplierProductsSlice.reducer;
export default supplierProductsReducer;

//Actions
//save product
export const addProduct = (productData) => async (dispatch) => {
  dispatch(supplierProductsSlice.actions.startLoading());
  try {
    const response = await axios.post("/add-product", productData);

    dispatch(
      supplierProductsSlice.actions.addProductSuccess(response.data.product)
    );
  } catch (error) {
    dispatch(supplierProductsSlice.actions.hasError(error));
  }
};

export const getAttributeOptions = createAsyncThunk(
  "supplierProducts/get-attribute-options",
  async ({ catId }) => {
    //
    const response = await axios.post("/get-variation-options", {
      categoryId: catId,
    });

    //

    return response.data;
  }
);

//list products
export const listProducts = () => async (dispatch) => {
  dispatch(supplierProductsSlice.actions.startLoading());
  try {
    const response = await axios.post("/list-products");
    dispatch(
      supplierProductsSlice.actions.listProductsSuccess(response.data.products)
    );
  } catch (error) {
    dispatch(supplierProductsSlice.actions.hasError(error));
  }
};

//get product
export const getProduct = (productData) => async (dispatch) => {
  dispatch(supplierProductsSlice.actions.startLoading());
  try {
    const response = await axios.post("/get-product", productData);
    dispatch(supplierProductsSlice.actions.requestReset());
    dispatch(
      supplierProductsSlice.actions.getProductSuccess(response.data.product)
    );
  } catch (error) {
    dispatch(supplierProductsSlice.actions.hasError(error));
  }
};

//get product dynamic description
export const getProductDynamicDescription =
  (productData) => async (dispatch) => {
    dispatch(supplierProductsSlice.actions.startLoading());
    try {
      const response = await axios.post(
        "/get-product-dynamic-description",
        productData
      );
      dispatch(
        supplierProductsSlice.actions.getProductDynamicDescriptionSuccess(
          response.data.productDynamicDesc
        )
      );
    } catch (error) {
      dispatch(supplierProductsSlice.actions.hasError(error));
    }
  };

//get product dynamic bullet description
export const getProductDynamicBulletDescription =
  (productData) => async (dispatch) => {
    dispatch(supplierProductsSlice.actions.startLoading());
    try {
      const response = await axios.post(
        "/get-product-dynamic-bullet-description",
        productData
      );
      dispatch(
        supplierProductsSlice.actions.getProductDynamicBulletDescriptionSuccess(
          response.data.productDynamicBulletDesc
        )
      );
    } catch (error) {
      dispatch(supplierProductsSlice.actions.hasError(error));
    }
  };

//update product
export const updateProduct = (productData) => async (dispatch) => {
  dispatch(supplierProductsSlice.actions.startLoading());
  try {
    const response = await axios.post("/update-product", productData);
    dispatch(
      supplierProductsSlice.actions.updateProductSuccess(response.data.product)
    );
  } catch (error) {
    dispatch(supplierProductsSlice.actions.hasError(error));
  }
};

//delete product
export const deleteProduct = (productData) => async (dispatch) => {
  dispatch(supplierProductsSlice.actions.startLoading());
  try {
    const response = await axios.post("/delete-product", productData);
    dispatch(
      supplierProductsSlice.actions.deleteProductSuccess(response.data.product)
    );
  } catch (error) {
    dispatch(supplierProductsSlice.actions.hasError(error));
  }
};

//get product variant
export const getProductVariant = (pId, vId) => async (dispatch) => {
  dispatch(supplierProductsSlice.actions.startLoading());
  try {
    const response = await axios.post("/get-product-variant-detail", {
      productId: pId,
      variantId: vId,
    });
    dispatch(
      supplierProductsSlice.actions.getProductVariantSuccess(
        response.data.variant
      )
    );
  } catch (error) {
    dispatch(supplierProductsSlice.actions.hasError(error));
  }
};
//save product variant
export const updateProductVariant = (variantData) => async (dispatch) => {
  dispatch(supplierProductsSlice.actions.startLoading());
  try {
    const response = await axios.post("/update-product-variant", variantData);
    dispatch(
      supplierProductsSlice.actions.updateVariantSuccess(response.data.variant)
    );
  } catch (error) {
    dispatch(supplierProductsSlice.actions.hasError(error));
  }
};

export const cleanupProducts = () => {
  return async (dispatch) => {
    dispatch(supplierProductsSlice.actions.cleanupProducts());
  };
};

export const productResetData = () => {
  return async (dispatch) => {
    dispatch(supplierProductsSlice.actions.requestReset());
  };
};
export const productResetRequest = () => {
  return async (dispatch) => {
    dispatch(supplierProductsSlice.actions.requestProductReset());
  };
};

/**
 * New Version product
 */
// add product
export const addProductBasic = createAsyncThunk(
  "supplierProducts/add-product-basic",
  async (productData) => {
    //
    const response = await axios.post("/add-product-basic", {
      ...productData,
    });
    //
    return response.data;
  }
);
// save product variant
export const saveProductVariants = createAsyncThunk(
  "supplierProducts/save-product-variants",
  async (variantData) => {
    //
    const response = await axios.post(
      `/save-product-variants/${variantData.productId}`,
      {
        ...variantData,
      }
    );
    //
    return response.data;
  }
);
// save product variant
export const saveProductImages = createAsyncThunk(
  "supplierProducts/save-product-images",
  async (imageData) => {
    //
    let config = {
      headers: {
        'content-type': 'multipart/form-data',
        // 'Content-Type': 'application/x-www-form-urlencoded'
      },
    };
    // const formData = new FormData();
    // formData.append("productId", imageData.productId);
    // formData.append("variantId", imageData.variantId);
    // formData.append("mainfile", imageData.mainfile);
    // formData.append("file1", imageData.file1);
    // formData.append("file2", imageData.file2);
    // formData.append("file3", imageData.file3);
    // formData.append("file4", imageData.file4);
    // formData.append("file5", imageData.file5);
    // formData.append("file6", imageData.file6);
    // formData.append("file7", imageData.file7);
    // formData.append("file8", imageData.file8);

    const response = await axios.post(
      `/save-product-images/${imageData.productId}`,
      {
        ...imageData,
      },
      // formData,
      config
    );
    //
    return response.data;
  }
);

// save product inventories
export const saveProductInventories = createAsyncThunk(
  "supplierProducts/save-product-inventories",
  async (inventoryData) => {
    //
    const response = await axios.post(
      `/save-product-inventories/${inventoryData.productId}`,
      {
        ...inventoryData,
      }
    );
    //
    return response.data;
  }
);

// update product basic
export const updateProductBasic = createAsyncThunk(
  "supplierProducts/update-product-basic",
  async (productData) => {
    //
    const response = await axios.put(`/update-product-basic/${productData.id}`, {
      ...productData,
    });
    //
    return response.data;
  }
);
