import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";

// Inventory Slice
const counterOfferSlice = createSlice({
  name: "counterOffer",
  initialState: {
    isLoading: false,
    offer: null,
    offers: [],
    offerAdded: false,
    offerUpdated: false,
    offerRejected: false,
  },
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.offerAdded = false;
      state.offerUpdated = false;
    },
    // Reset LOADING
    requestReset(state) {
      state.offerAdded = false;
      state.isLoading = false;
      state.offer = null;
      state.error = null;
      state.offerUpdated = false;
      state.offerRejected = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.offerAdded = false;
    },
    // NEW COUNTER OFFER
    newCounterOfferSuccess(state, action) {
      state.isLoading = false;
      state.offerAdded = true;
    },
    // LIST CUSTOMER OFFERS
    listCounterOffersSuccess(state, action) {
      state.isLoading = false;
      state.offers = action.payload;
      state.offerAdded = false;
    },
    // LIST SUPPLIER OFFERS
    listSupplierCounterOffersSuccess(state, action) {
      state.isLoading = false;
      state.offers = action.payload.sort((a,b) => new Date(a.created_at) > new Date(b.created_at) ? -1 : 1);
    },
    // GET CUSTOMER QUOTE
    rejectCounterOfferSuccess(state, action) {
      state.isLoading = false;
      state.offerRejected = true;
      state.offer = action.payload;
    },

    cleanupOffers (state) {
      state.offers = null;
    }
  },
});

const counterOfferReducer = counterOfferSlice.reducer;
export default counterOfferReducer;

//Actions
//reinit
export function resetLoading() {
  return async (dispatch) => {
    dispatch(counterOfferSlice.actions.requestReset());
  };
}


export const cleanupCounterOffers = () =>  {
  return async (dispatch) => {
    dispatch(counterOfferSlice.actions.cleanupOffers());
  }
}
//counter offer
export const newCounterOffer = (offerData) => async (dispatch) => {
  dispatch(counterOfferSlice.actions.startLoading());
  try {
    const response = await axios.post("/new-counter-offer", {
      ...offerData,
    });
    dispatch(
      counterOfferSlice.actions.newCounterOfferSuccess(response.data.offer)
    );
  } catch (error) {
    dispatch(counterOfferSlice.actions.hasError(error));
  }
};

//list counterOffers
export const listCounterOffers = (quoteId, supplierId) => async (dispatch) => {
  //  return;
  dispatch(counterOfferSlice.actions.startLoading());
  try {
    if (supplierId !== null && supplierId !== undefined && supplierId !== "" &&
    quoteId !== null && quoteId !== undefined && quoteId !== "" 
    ) {
    const response = await axios.post("/list-counter-offers", {
      quote_id: quoteId,
      supplier_id: supplierId,
    });
    dispatch(
      counterOfferSlice.actions.listCounterOffersSuccess(response.data.offers)
    );
    }
  } catch (error) {
    dispatch(counterOfferSlice.actions.hasError(error));
  }
};

//list supplier counterOffers
export const listSupplierCounterOffers = (supplierId) => async (dispatch) => {
  //  return;
  dispatch(counterOfferSlice.actions.startLoading());
  try {
    const response = await axios.post("/list-supplier-offers", {
      supplier_id: supplierId,
    });
    dispatch(
      counterOfferSlice.actions.listSupplierCounterOffersSuccess(response.data.offers)
    );
  } catch (error) {
    dispatch(counterOfferSlice.actions.hasError(error));
  }
};

//reject counter offer
export const rejectCounterOffer = (quoteData) => async (dispatch) => {
  dispatch(counterOfferSlice.actions.startLoading());
  try {
    const response = await axios.post("/reject-counter-offer", {
      ...quoteData,
    });
    dispatch(
      counterOfferSlice.actions.rejectCounterOfferSuccess(response.data.offer)
    );
  } catch (error) {
    dispatch(counterOfferSlice.actions.hasError(error));
  }
};


