import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
import { dispatch } from "../store";
import { getAllcarts } from "./Cart";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  loanQualification: null,
  qualificationSaved: false,
  messageSent: false,
  messageSending: false,
  isSanction: false,
};

const financer = createSlice({
  name: "financer",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.qualificationSaved = false;
      state.isSanction = false;
    },
    // Reset LOADING
    requestReset(state) {
      state.isLoading = false;
      state.qualificationSaved = false;
      state.isSanction = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      // state.qualificationSaved = false;
    },

    messageReset(state, action) {
      state.messageSending = false;
      state.messageSent = false;
    },
    // getOrderPlacedSuccess(state, action){
    //   state.isLoading = false;
    //   state.isPlaced = true
    // }
  },
  extraReducers: (builder) => {
    builder.addCase(saveLoanQualification.pending, (state) => {
      state.isLoading = true;
      state.qualificationSaved = false;
    });
    builder.addCase(saveLoanQualification.fulfilled, (state) => {
      state.isLoading = false;
      state.qualificationSaved = true;
    });
    builder.addCase(saveLoanQualification.rejected, (state) => {
      state.isLoading = false;
      state.qualificationSaved = false;
    });
    builder.addCase(getLoanQualification.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getLoanQualification.fulfilled, (state, action) => {
      state.isLoading = false;
      state.qualificationSaved = false;
      state.loanQualification = action.payload.loanInfo;
    });
    builder.addCase(getLoanQualification.rejected, (state) => {
      state.isLoading = false;
      state.loanQualification = null;
    });

    // Message
    builder.addCase(sendLoanOfferMsg.pending, (state, action) => {
      state.messageSending = true;
    });
    builder.addCase(sendLoanOfferMsg.fulfilled, (state, action) => {
      state.messageSending = true;
      state.messageSent = true;
    });
    builder.addCase(sendLoanOfferMsg.rejected, (state, action) => {
      state.messageSending = false;
      state.messageSent = true;
    });

    builder.addCase(sanctionLoanOffer.pending, (state, action) => {
      state.isSanction = false;
    });
    builder.addCase(sanctionLoanOffer.fulfilled, (state, action) => {
      state.isSanction = action.payload?.success;
    });
    builder.addCase(sanctionLoanOffer.rejected, (state, action) => {
      state.isSanction = false;
    });
  },
});

// Reducer
export default financer.reducer;

// Actions
// export const { savecustomerBasicInfoSuccess } = slice.actions;

// ----------------------------------------------------------------------
//reinit
export function resetLoading() {
  return async (dispatch) => {
    dispatch(financer.actions.requestReset());
  };
}

export const saveLoanQualification = createAsyncThunk(
  //action type string
  "financer/save-loan-criteria",
  // callback function
  async (requestData, dispatch) => {
    const response = await axios.post("save-loan-criteria", requestData);
    return response.data;
  }
);

export const getLoanQualification = createAsyncThunk(
  //action type string
  "financer/get-loan-criteria",
  // callback function
  async () => {
    const response = await axios.get("get-loan-criteria");
    return response.data;
  }
);

export const editLoanQualification = createAsyncThunk(
  //action type string
  "financer/get-loan-criteria",
  // callback function
  async (id) => {
    const response = await axios.get(`get-loan-criteria?id=${id}`);
    return response.data;
  }
);

// delete loan criteria
export const deleteLoanCriteria = createAsyncThunk(
  "financer/delete-loan-criteria",
  // callback function
  async (loan_type_id) => {
    const response = await axios.get(`delete-loan-criteria/${loan_type_id}`);
    return response.data;
  }
);

export const proceedLoanOffer = createAsyncThunk(
  "financer/proceed-loan-offer",
  // callback function
  async (loanId, dispatch) => {
    const response = await axios.get(`proceed-loan-offer/${loanId}`);
    return response.data;
  }
);
export const sendLoanOfferMsg = createAsyncThunk(
  "financer/send-message",
  // callback function
  async ({ loanId, message, sendOffer }) => {
    var params = new URLSearchParams();
    params.append("message", message.message);
    if (sendOffer) {
      params.append("tenure", message.tenure);
      params.append("roi", message.roi);
      params.append("amount", message.amount);
      params.append("processingFee", message.processingFee);
    }

    const response = await axios.post(
      `send-financer-loan-offer/${loanId}`,
      params,
      {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      }
    );
    // const response = await axios.post(`send-loan-message/${loanId}`, params, {
    //   headers: { "content-type": "application/x-www-form-urlencoded" },
    // });
    return response.data;
  }
);

export const sanctionLoanOffer = createAsyncThunk(
  "sanction-loan-offer",
  async (data) => {
    const response = await axios.post(`/sanction-loan-offer`, data);
    return response.data;
  }
);
// Reseter

export const resetMessage = (dispatch) => {
  dispatch(financer.actions.messageReset());
};
