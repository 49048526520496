import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
import { dispatch } from "../store";
import { getAllcarts } from "./Cart";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  Address: null,
  isSaved: false,
  isPlaced: false,
  billingAddress: null,
  shippingAddress: null,
  billingCount: 0,
  shippingCount: 0,
};

const Customer = createSlice({
  name: "customer",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // Reset LOADING
    requestReset(state) {
      state.isSaved = false;
      state.isPlaced = false;
      state.isOrderPlaced = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;

      state.error = action.payload;
      state.isSaved = false;
    },

    // GET customer BASIC INFO
    // getAddressSuccess(state, action) {
    //   state.isLoading = false;
    //   state.shippingCount = action.payload.shippingAddresses.length;
    //   state.billingCount = action.payload.billingAddresses.length;
    //   action.payload?.shippingAddresses?.sort((a) => (a.is_default == 0) ? 1 : -1)
    //   action.payload?.billingAddresses?.sort((a) => (a.is_default == 0) ? 1 : -1)
    //   state.Address = action.payload;
    //   state.isSaved = true
    // },
    getOrderPlacedSuccess(state, action) {
      state.isLoading = false;
      state.isPlaced = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(placeOrder.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(placeOrder.fulfilled, (state) => {
      state.isLoading = false;
      state.isPlaced = true;
    });
    builder.addCase(placeOrder.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getAddress.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getAddress.fulfilled, (state, action) => {
      state.isLoading = false;
      state.shippingCount = action.payload.shippingAddresses.length;
      state.billingCount = action.payload.billingAddresses.length;
      action.payload?.shippingAddresses?.sort((a) =>
        a.is_default == 0 ? 1 : -1
      );
      action.payload?.billingAddresses?.sort((a) =>
        a.is_default == 0 ? 1 : -1
      );
      state.Address = action.payload;
      state.isSaved = true;
    });
  },
});

// Reducer
export default Customer.reducer;

// Actions
// export const { savecustomerBasicInfoSuccess } = slice.actions;

// ----------------------------------------------------------------------
//reinit
export function resetLoading() {
  return async (dispatch) => {
    dispatch(Customer.actions.requestReset());
  };
}
// Get Address
// export function getAddress(requestData) {
//   return async (dispatch) => {
//     dispatch(Customer.actions.startLoading());
//     try {
//       const response = await axios.post(
//         "/get-supplier-basic-info",
//         requestData
//       );
//       //
//       dispatch(
//         Customer.actions.getAddressSuccess(
//           response.data.supplierBasicInfo
//         )
//         );
//     } catch (error) {
//       //
//       dispatch(Customer.actions.hasError(error));
//     }
//   };
// }

export const getAddress = createAsyncThunk(
  "customer/getAddress",
  async (requestData) => {
    const response = await axios.post("/get-supplier-basic-info", requestData);
    return response.data.supplierBasicInfo;
  }
);

export const placeOrder = createAsyncThunk(
  //action type string
  "cart/place-order",
  // callback function
  async (requestData, dispatch) => {
    const response = await axios.post("customer/place-order", requestData);
    return response.data;
  }
);
