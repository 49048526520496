import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
// slices
import commonReducer from "./slices/common";
import settingsReducer from "./slices/settings";
import userReducer from "./slices/user";
import wishlistReducer from "./slices/wishlist";
import inventoriesReducer from "./slices/inventories";
import supplierProductsReducer from "./slices/supplierProducts";
import productsReducer from "./slices/products";
import quotesReducer from "./slices/quotes";
import supplierQuotesReducer from "./slices/SupplierQuotes";
import onboardingReducer from "./slices/onboarding";
import balanceSheetReducer from "./slices/balanceSheet";
import financialAccountsStatementReducer from "./slices/financialAccountsStatement";
import incomeStatementReducer from "./slices/incomeStatement";
import customerReducer from "./slices/Customer";
import cartReducer from "./slices/Cart";
import counterOffer from "./slices/counterOffer";
import reviewReducer from "./slices/Review";
import customerOrders from "./slices/customerOrders";
import supplierOrdersReducer from "./slices/SupplierOrders";
import financerReducer from "./slices/financer";
import loanReducer from "./slices/loan";
import loanOffersReducer from "./slices/loanOffers";
import globalSearchReducer from "./slices/globalSearch";
import orderReducer from "./slices/Order";
import inquiryReducer from "./slices/inquiry";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const rootReducer = combineReducers({
  common: commonReducer,
  settings: settingsReducer,
  user: userReducer,
  wishlist: wishlistReducer,
  balanceSheet: balanceSheetReducer,
  onboarding: onboardingReducer,
  supplierProducts: supplierProductsReducer,
  products: productsReducer,
  quotes: quotesReducer,
  supplierQuotes: supplierQuotesReducer,
  inventories: inventoriesReducer,
  financialAccountsStatement: financialAccountsStatementReducer,
  incomeStatement: incomeStatementReducer,
  customer: customerReducer,
  cart: cartReducer,
  counterOffer: counterOffer,
  review: reviewReducer,
  customerOrders: customerOrders,
  supplierOrders: supplierOrdersReducer,
  financer: financerReducer,
  loan: loanReducer,
  loanOffers: loanOffersReducer,
  globalSearch: globalSearchReducer,
  order: orderReducer,
  inquiry: inquiryReducer,
});

export { rootPersistConfig, rootReducer };
