import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";


const SupplierOrdersSlice = createSlice({
    name : "supplierOrders",
    initialState: {
        isLoading: false,
        order: null,
        orders: [],
        isEmpty : false
      },

    reducers : {
        // START LOADING
    startLoading(state) {
        state.isLoading = true;
      },
      // Reset LOADING
      requestReset(state) {
        state.order = null;
        state.error = null;
      },
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },
      // LIST INVENTORIES
      listSupplierOrdersSuccess(state, action) {
        state.isLoading = false;
        state.orders = action.payload;
        if (action.payload === '' || action.payload === undefined  || action.payload === null) {
          state.isEmpty = true
        }
        state.order = null;
      },
      // Order Detail
      getSupplierOrderSuccess(state, action) {
        state.isLoading = false;
        state.order = action.payload;
      },
    },
    extraReducers  : (builder) =>  {
        builder.addCase(
            getSupplierOrder.pending , (state) => {
                state.isLoading = true
            }
        )
        builder.addCase(getSupplierOrder.fulfilled , (state, action) =>  {
                state.isLoading = false
                state.order = action.payload.order
        })

        builder.addCase(getSupplierOrder.rejected , (state) => {
            state.isLoading = false
        })
    }   
})

//Actions
//reinit
export function resetLoading() {
    return async (dispatch) => {
      dispatch(SupplierOrdersSlice.actions.requestReset());
    };
  }
  
  
  //list SupplierOrders
  export const listSupplierOrders = () => async (dispatch) => {
    //  return;
    dispatch(SupplierOrdersSlice.actions.startLoading());
    try {
      const response = await axios.post("/supplier/orders");
      dispatch(SupplierOrdersSlice.actions.listSupplierOrdersSuccess(response.data.orders));
    } catch (error) {
      // 
      dispatch(SupplierOrdersSlice.actions.hasError(error));
    }
  };
  
  //get SupplierOrder
  export const getSupplierOrder = createAsyncThunk(
    //action type string
    "supplier/order-detail",
    // callback function
    async (SupplierOrderData) => {
      const response = await axios.post("/supplier/order-detail", SupplierOrderData);
      return response.data;
    }
  );

  const supplierOrdersReducer = SupplierOrdersSlice.reducer;
export default supplierOrdersReducer;