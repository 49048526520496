import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  loading: false,
  error: false,
  financialBalanceSheetData: [],
  financialData: [],
  financialDataAddMsg: "",
  isFinancialDataAdded: false,
  isFinancialDataServerActionStatus: false,
  isFinancialDataSaved: false,
  isDeleted : false
};

export const getFinancialStatement = createAsyncThunk(
  //action type string
  "financialAccountsStatement/get-financial-accounts-statements",
  // callback function
  async () => {
    const response = await axios.post(`/get-financial-accounts-statements`)
        return response.data.financialData.data;
  }
);


export const getFinancialStatementById = createAsyncThunk(
  //action type string
  "financialAccountsStatement/get-financial-account-statement-by-id",
  // callback function
  async ({ id }) => {
    const response = await axios.post(
      `/get-financial-account-statement-by-id`,
      {
        id: id,
      }
    );
    return response.data;
  }
);

export const addFinancialBalanceSheet = createAsyncThunk(
  //action type string
  "financialAccountsStatement/add-financial-accounts-statements",
  // callback function
  async (data) => {
    
    const response = await axios.post(`/add-financial-accounts-statements`, {
      data,
    });
    return response.data;
  }
);
export const deleteFinancialStatement = createAsyncThunk(
  //action type string
  "financialAccountsStatement/delete-financial-accounts-statements",
  // callback function
  async (id) => {
    const response = await axios.post(`/delete-financial-accounts-statements`, {
      id: id,
    });
    return response.data;
  }
);

const slice = createSlice({
  name: "financialAccountsStatement",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.isSaved = false;
    },
    // Reset LOADING
    requestReset(state) {
      state.isSaved = false;
      state.isLoading= false
      state.pageData = null;
      state.error = false;
      state.isFinancialDataSaved = false;
      state.isDeleted = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.isSaved = false;
    },
    cleanupFinance(state) {
      state.isLoading = false;
      state.financialData = null;
    }
  },
  extraReducers: (builder) => {
    //Get Financial Statement By ID
    builder.addCase(getFinancialStatementById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFinancialStatementById.fulfilled, (state, action) => {
      state.loading = false;
      state.financialData = action.payload.financialData;
    });
    builder.addCase(getFinancialStatementById.rejected, (state, action) => {
      state.loading = false;
    });
    //Get Financial Statements
    builder.addCase(getFinancialStatement.pending, (state) => {
      state.loading = true;
      state.error = false
    });
    builder.addCase(getFinancialStatement.fulfilled, (state, action) => {
      state.loading = false;
      state.financialData = action.payload;
      state.error = false
    });
    builder.addCase(getFinancialStatement.rejected, (state, action) => {
      state.loading = false;
      state.error = true
    });

    //Add financialData
    builder.addCase(addFinancialBalanceSheet.pending, (state) => {
      state.loading = true;
      state.financialDataAddMsg = "";
      state.isFinancialDataAdded = false;
      state.isFinancialDataServerActionStatus = false;
    });
    builder.addCase(addFinancialBalanceSheet.fulfilled, (state, action) => {
      state.loading = false;
      state.financialData = action.payload.wishlist;
      state.isFinancialDataServerActionStatus = action.payload.success;
    });
    builder.addCase(addFinancialBalanceSheet.rejected, (state) => {
      state.loading = false;
    });

    //Delete wishlist
    builder.addCase(deleteFinancialStatement.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteFinancialStatement.fulfilled, (state) => {
      state.loading = false;
      state.isDeleted = true
    });
    builder.addCase(deleteFinancialStatement.rejected, (state) => {
      state.loading = false;
    });
  },
});

// Reducer
export default slice.reducer;

//reinit
export function resetLoading() {
  return async (dispatch) => {
    dispatch(slice.actions.requestReset());
  };
}

export function cleanupFinance() {
  return async (dispatch) => {
    dispatch(slice.actions.cleanupFinance());
  }
}
