import React from "react";

const DynamicTitle = ({ data }) => {
  return (
    <>
      {data?.title}
      {data?.attributes !== undefined && (
        <>
          {" "}
          {data?.attributes !== undefined && data?.attributes !== null && (
            <small style={{ fontSize: "12px" }}>
              ({data?.attributes?.replace(/['"]+/g, "")})
            </small>
          )}
        </>
      )}
    </>
  );
};

export default DynamicTitle;
