import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  loading: false,
  error: false,
  financialBalanceSheetData: [],
  incomeStatementData: [],
  incomeStatementDataAddMsg: "",
  isIncomeStatementDataAdded: false,
  isIncomeStatementDataServerActionStatus: false,
  isIncomeStatementDataSaved: false,
  maxLoanAmount: 0,
  incomeData : null,
  incomeStatementDeleted : false
};

export const calculateLoanAmount = createAsyncThunk(
  //action type string
  "incomeStatement/calculate-loan-eligibility",
  // callback function
  async () => {
    const response = await axios.post(`/calculate-loan-eligibility`);
    return response.data;
  }
);
export const getIncomeStatement = createAsyncThunk(
  //action type string
  "incomeStatement/get-income-statements",
  // callback function
  async () => {
    const response = await axios.post(`/get-income-statements`);
    return response.data;
  }
);

export const getIncomeStatementById = createAsyncThunk(
  //action type string
  "incomeStatement/get-income-statement-by-id",
  // callback function
  async ({ id }) => {
    const response = await axios.post(`/get-income-statement-by-id`, {
      id: id,
    });
    return response.data;
  }
);

export const addIncomeStatement = createAsyncThunk(
  //action type string
  "incomeStatement/add-income-statement",
  // callback function
  async (data) => {
    
    const response = await axios.post(`/add-income-statement`, {
      data,
    });
    return response.data;
  }
);
export const deleteIncomeStatement = createAsyncThunk(
  //action type string
  "incomeStatement/delete-income-statement",
  // callback function
  async (id) => {
    const response = await axios.post(`/delete-income-statement`, {
      id: id,
    });
    return response.data;
  }
);

const slice = createSlice({
  name: "incomeStatement",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.isSaved = false;
    },
    // Reset LOADING
    requestReset(state) {
      state.isSaved = false;
      state.pageData = null;
      state.error = null;
      state.isIncomeStatementDataSaved = false;
      state.incomeStatementDeleted = false
    },
    cleanupIncome(state) {
      state.incomeData = null
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.isSaved = false;
    },
  },
  extraReducers: (builder) => {
    //Calculate max loan
    builder.addCase(calculateLoanAmount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(calculateLoanAmount.fulfilled, (state, action) => {
      state.loading = false;
      state.maxLoanAmount = action.payload.max_loan_amount;
    });
    builder.addCase(calculateLoanAmount.rejected, (state, action) => {
      state.loading = false;
    });
    //Get IncomeStatement Statement By ID
    builder.addCase(getIncomeStatementById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getIncomeStatementById.fulfilled, (state, action) => {
      state.loading = false;
      state.incomeData = action.payload;
    });
    builder.addCase(getIncomeStatementById.rejected, (state, action) => {
      state.loading = false;
    });
    //Get IncomeStatement Statements
    builder.addCase(getIncomeStatement.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getIncomeStatement.fulfilled, (state, action) => {
      state.loading = false;
      state.incomeData = action.payload.incomeData.data;
    });
    builder.addCase(getIncomeStatement.rejected, (state, action) => {
      state.loading = false;
    });

    //Add incomeData
    builder.addCase(addIncomeStatement.pending, (state) => {
      state.loading = true;
      state.incomeDataAddMsg = "";
      state.isIncomeStatementDataAdded = false;
      state.isIncomeStatementDataServerActionStatus = false;
    });
    builder.addCase(addIncomeStatement.fulfilled, (state, action) => {
      state.loading = false;
      state.incomeData = action.payload.wishlist;
      state.isIncomeStatementDataServerActionStatus = action.payload.success;
    });
    builder.addCase(addIncomeStatement.rejected, (state) => {
      state.loading = false;
    });

    //Delete wishlist
    builder.addCase(deleteIncomeStatement.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteIncomeStatement.fulfilled, (state) => {
      state.loading = false;
      state.incomeStatementDeleted = true
    });
    builder.addCase(deleteIncomeStatement.rejected, (state) => {
      state.loading = false;
    });
  },
});

// Reducer
export default slice.reducer;

//reinit
export function resetLoading() {
  return async (dispatch) => {
    dispatch(slice.actions.requestReset());
  };
}

export function cleanupIncome() {
  return async (dispatch) => {
    dispatch(slice.actions.cleanupIncome())
  }
}