import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
// Alert
import Swal from "sweetalert2";

// Inventory Slice
const inventoriesSlice = createSlice({
  name: "inventories",
  initialState: {
    isLoading: false,
    inventory: null,
    inventories: [],
    inventoryProducts: [],
    inventoryUpdated: false,
  },
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.inventoryUpdated = false;
    },
    // Reset LOADING
    requestReset(state) {
      state.inventoryUpdated = false;
      state.inventory = null;
      state.error = null;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.inventoryUpdated = false;
    },
    // LIST INVENTORIES
    listInventoriesSuccess(state, action) {
      state.isLoading = false;
      state.inventories = action.payload.inventories;
      state.inventoryProducts = action.payload.inventoryProducts;
      state.inventory = null;
      state.inventoryUpdated = false;
    },
    // UPDATE INVENTORY
    updateInventorySuccess(state, action) {
      state.isLoading = false;
      state.inventory = action.payload;
      state.inventoryUpdated = true;
    },
    inventoriesCleanup(state, action) {
      state.inventories = null
    }
  },
});

const inventoriesReducer = inventoriesSlice.reducer;
export default inventoriesReducer;

//Actions
//reinit
export function resetLoading() {
  return async (dispatch) => {
    dispatch(inventoriesSlice.actions.requestReset());
  };
}


export const inventoriesCleanup = () => {
  return async (dispatch) => {
    dispatch(inventoriesSlice.actions.inventoriesCleanup())
  }
}
// list inventories
export const listInventories = () => async (dispatch) => {
  //  return;
  dispatch(inventoriesSlice.actions.startLoading());
  try {
    const response = await axios.post("/list-inventories");
    dispatch(inventoriesSlice.actions.updateInventorySuccess(response.data.inventories));
  } catch (error) {
    // 
    dispatch(inventoriesSlice.actions.hasError(error));
  }
};
export const listInventoriesByID = ({id, sku}) => async (dispatch) => {
  //  return;
  dispatch(inventoriesSlice.actions.startLoading());
  try {
    const response = await axios.post(`/list-inventories/${id}?sku=${sku}`);
    dispatch(inventoriesSlice.actions.listInventoriesSuccess(response.data));
  } catch (error) {
    // 
    dispatch(inventoriesSlice.actions.hasError(error));
  }
};

//get inventory
export const getInventory = (inventoryData) => async (dispatch) => {
  //  return;
  dispatch(inventoriesSlice.actions.startLoading());
  try {
    const response = await axios.post("/get-inventory", inventoryData);
    dispatch(inventoriesSlice.actions.getInventorySuccess(response.data.inventory));
  } catch (error) {
    // 
    dispatch(inventoriesSlice.actions.hasError(error));
  }
};

//update inventory
export const updateInventory = (inventoryData) => async (dispatch) => {
  //  return;
  dispatch(inventoriesSlice.actions.startLoading());
  try {
    const response = await axios.post("/update-inventory", inventoryData);
    dispatch(inventoriesSlice.actions.updateInventorySuccess(response.data.inventory));
  } catch (error) {
    // 
    dispatch(inventoriesSlice.actions.hasError(error));
  }
};
