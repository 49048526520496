import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  docTypes: null,
  loanInfo: null,
  additionalDocs: null,
  loanData: null,
  prevData: null,
  supportedDoc: null,
  isSaved: false,
  isAdded: false,
  prevSaved: false,
  panVerified: false,
  gstVerified: false,
  cibilData: null,
  financialData: null,
  bankData: null,
  scoreData: null,
  getScores: false,
  bank_scorecard: null,
  cibil_scorecard: null,
  financial_scorecard: null,
  loanList: null,
  checkSteps: null,
  viewFinancial: null,
  viewBank: null,
  viewCibil: null,
  fileData: null,
  financialInfo: null,
  financialScore: null,
  bankScore: null,
  bankInfo: null,
  cibilScore: null,
  cibilInfo: null,
  trexoScore: null,
  basicInfo: null,
  tmpLoanId: null,
  tmpLoanIdLoaded: false,
  prevFile: null,
  currFile: null,
  loanCompleted: null,
  isDeleted: false,
  products: [],
};

const loan = createSlice({
  name: "loan",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.isSaved = false;
      state.prevSaved = false;
      state.gstVerified = false;
      state.panVerified = false;
      state.loanData = null;
      state.isAdded = false;
    },
    // Reset LOADING
    requestReset(state) {
      state.prevSaved = false;
      state.isLoading = false;
      state.isSaved = false;
      state.gstVerified = false;
      state.panVerified = false;
      state.isAdded = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.prevSaved = false;
      state.isLoading = false;
      state.isSaved = false;
      state.error = action.payload;
      state.gstVerified = false;
      state.panVerified = false;
      state.isAdded = false;
      // state.isSaved = false;
    },
    getLoanListSuccess(state, action) {
      state.isLoading = false;
      state.isSaved = true;
      state.loanList = action.payload;
    },
    pendingStepsSuccess(state, action) {
      state.isLoading = false;
      state.checkSteps = action.payload;
    },
    viewFinancialSuccess(state, action) {
      state.isLoading = false;
      state.isSaved = true;
      state.viewFinancial = action.payload;
    },
    viewBankSuccess(state, action) {
      state.isLoading = false;
      state.isSaved = true;
      state.viewBank = action.payload;
    },
    viewCibilSuccess(state, action) {
      state.isLoading = false;
      state.isSaved = true;
      state.viewCibil = action.payload;
    },
    getLoanOffersSuccess(state, action) {
      state.isLoading = false;
      state.isSaved = true;
      state.financialInfo = action.payload?.financial_informations;
      state.bankInfo = action.payload?.bank_informations;
      state.financialScore = action.payload?.financial_scorecard;
      state.bankScore = action.payload?.bank_scorecard;
      state.cibilScore = action.payload?.cibil_scorecard;
      state.cibilInfo = action.payload?.loan?.cibil_statement;
      state.basicInfo = action.payload?.loan?.user;
      state.trexoScore = action.payload?.trexo_scorecard;
    },
    saveLoanApplicationSuccess(state, action) {
      state.isLoading = false;
      state.loanData = action.payload?.loanData;
      state.isSaved = true;
    },
    getAdditionalDocumentsSuccess(state, action) {
      state.prevSaved = true;
      state.isLoading = false;
      state.additionalDocs = action.payload;
      state.isSaved = false;
    },
    getProductsDetailsSuccess(state, action) {
      state.products = action.payload;
      state.isLoading = false;
      state.isSaved = true;
    },
    saveLoanDocumentKycSuccess(state, action) {
      state.isLoading = false;
      state.loanData = action.payload?.loanData;
      state.isSaved = true;
    },
    saveLoanAdditionalDocumentsSuccess(state, action) {
      state.isLoading = false;
      state.loanData = action.payload?.loanData;
      state.isSaved = true;
    },
    // uploadFinancialStatementSuccess(state, action) {
    //   state.isLoading = false;
    //   state.isSaved = true;
    // },
    uploadCibilStatementSuccess(state, action) {
      state.isLoading = false;
      state.isSaved = true;
    },
    uploadBankStatementSuccess(state, action) {
      state.isLoading = false;
      state.isSaved = true;
    },
    termConditionsSuccess(state, action) {
      state.isLoading = false;
      state.loanCompleted = action.payload;
      state.isSaved = false;
      state.loanData = null;
    },
    setTmpLoanId(state, action) {
      state.tmpLoanId = action.payload;
      state.tmpLoanIdLoaded = true;
    },
    resetTmpLoanId(state, action) {
      state.tmpLoanIdLoaded = false;
    },
    uploadSupportedDocumentsSuccess(state, action) {
      state.isLoading = false;
      state.isAdded = true;
      state.supportedDoc = action.payload;
    },
    // verifyGSTSuccess(state, action) {
    //   state.isLoading = false;
    //   state.gstVerified = true;
    // },
    // verifyPANSuccess(state, action) {
    //   state.isLoading = false;
    //   state.panVerified = true;
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(verifyGST.pending, (state) => {
      state.isLoading = true;
      state.gstVerified = false;
    });
    builder.addCase(verifyGST.fulfilled, (state) => {
      state.isLoading = false;
      state.gstVerified = true;
    });
    builder.addCase(verifyGST.rejected, (state) => {
      state.isLoading = false;
      state.gstVerified = false;
    });
    builder.addCase(verifyPAN.pending, (state) => {
      state.isLoading = true;
      state.panVerified = false;
    });
    builder.addCase(verifyPAN.fulfilled, (state) => {
      state.isLoading = false;
      state.panVerified = true;
    });
    builder.addCase(verifyPAN.rejected, (state) => {
      state.isLoading = false;
      state.panVerified = false;
    });
    builder.addCase(getLoanApplication.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getLoanApplication.fulfilled, (state, action) => {
      state.isLoading = false;
      state.loanData = action.payload?.loanData;
      state.isSaved = true;
    });
    builder.addCase(getLoanApplication.rejected, (state) => {
      state.isLoading = false;
    });
    // builder.addCase(saveLoanApplication.pending, (state) => {
    //   state.isLoading = true;
    // });
    // builder.addCase(saveLoanApplication.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   state.loanData = action.payload?.loanData;
    //   state.isSaved = true;
    // });
    // builder.addCase(saveLoanApplication.rejected, (state) => {
    //   state.isLoading = false;
    // });
    // builder.addCase(saveLoanDocumentKyc.pending, (state) => {
    //   state.isLoading = true;
    // });
    // builder.addCase(saveLoanDocumentKyc.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   state.loanData = action.payload?.loanData;
    //   state.isSaved = true;
    // });
    // builder.addCase(saveLoanDocumentKyc.rejected, (state) => {
    //   state.isLoading = false;
    // });
    builder.addCase(loanVerificationGST.pending, (state) => {
      state.isLoading = true;
      state.gstVerified = false;
    });
    builder.addCase(loanVerificationGST.fulfilled, (state) => {
      state.isLoading = false;
      state.isSaved = true;
      state.gstVerified = true;
    });
    builder.addCase(loanVerificationGST.rejected, (state) => {
      state.isLoading = false;
      state.gstVerified = false;
    });

    // upload financial statement
    builder.addCase(uploadFinancialStatement.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(uploadFinancialStatement.fulfilled, (state) => {
      state.isLoading = false;
      state.isSaved = true;
    });
    builder.addCase(uploadFinancialStatement.rejected, (state) => {
      state.isLoading = false;
    });

    //upload cibil statement
    // builder.addCase(uploadCibilStatement.pending, (state) => {
    //   state.isLoading = true;
    // });
    // builder.addCase(uploadCibilStatement.fulfilled, (state) => {
    //   state.isLoading = false;
    //   state.isSaved = true;
    // });
    // builder.addCase(uploadCibilStatement.rejected, (state) => {
    //   state.isLoading = false;
    // });
    // upload bank statement
    // builder.addCase(uploadBankStatement.pending, (state) => {
    //   state.isLoading = true;
    // });
    // builder.addCase(uploadBankStatement.fulfilled, (state) => {
    //   state.isLoading = false;
    //   state.isSaved = true;
    // });
    // builder.addCase(uploadBankStatement.rejected, (state) => {
    //   state.isLoading = false;
    // });

    //get pre qualification
    builder.addCase(getPreQualification.pending, (state) => {
      state.isLoading = true;
      state.prevSaved = false;
    });
    builder.addCase(getPreQualification.fulfilled, (state, action) => {
      state.prevData = action.payload.data;
      state.isLoading = false;
      state.prevSaved = true;
    });
    builder.addCase(getPreQualification.rejected, (state) => {
      state.isLoading = false;
      state.prevSaved = false;
    });

    //Get pre qualification document
    builder.addCase(getPreQualificationDocument.pending, (state) => {
      state.isLoading = true;
      state.prevSaved = false;
    });
    builder.addCase(getPreQualificationDocument.fulfilled, (state, action) => {
      state.prevData = action.payload?.data;
      state.isLoading = false;
      state.prevSaved = true;
    });
    builder.addCase(getPreQualificationDocument.rejected, (state) => {
      state.isLoading = false;
      state.prevSaved = false;
    });

    //Get pre qualification financial
    builder.addCase(getPreQualificationFinancial.pending, (state) => {
      state.isLoading = true;
      state.prevSaved = false;
    });
    builder.addCase(getPreQualificationFinancial.fulfilled, (state, action) => {
      state.prevData = action.payload?.information;
      state.fileData = action.payload?.data;
      state.prevFile = action.payload?.previous_year_statement;
      state.currFile = action.payload?.current_year_statement;
      state.isLoading = false;
      state.prevSaved = true;
      state.isSaved = false;
    });
    builder.addCase(getPreQualificationFinancial.rejected, (state) => {
      state.isLoading = false;
      state.prevSaved = false;
    });

    //Get pre cibil manual bank statement
    builder.addCase(getPreCibilData.pending, (state) => {
      state.isLoading = true;
      state.prevSaved = false;
    });
    builder.addCase(getPreCibilData.fulfilled, (state, action) => {
      // console.log('action.payload', action.payload)
      state.prevData = action.payload;
      state.isLoading = false;
      state.prevSaved = true;
    });
    builder.addCase(getPreCibilData.rejected, (state) => {
      state.isLoading = false;
      state.prevSaved = false;
    });

    //Get pre qualification bank statement
    builder.addCase(getPreQualificationBankStatement.pending, (state) => {
      state.isLoading = true;
      state.prevSaved = false;
    });
    builder.addCase(
      getPreQualificationBankStatement.fulfilled,
      (state, action) => {
        state.prevData = action.payload?.data;
        state.isLoading = false;
        state.prevSaved = true;
      }
    );
    builder.addCase(getPreQualificationBankStatement.rejected, (state) => {
      state.isLoading = false;
      state.prevSaved = false;
    });

    // get cibil score
    builder.addCase(getCibilScore.pending, (state) => {
      state.isLoading = true;
      state.getScores = false;
    });
    builder.addCase(getCibilScore.fulfilled, (state, action) => {
      state.cibilData = action.payload?.scorecard;
      state.isLoading = false;
      state.getScores = true;
    });
    builder.addCase(getCibilScore.rejected, (state) => {
      state.isLoading = false;
      state.getScores = false;
    });

    // get Financial statement score card
    builder.addCase(getFinancialStatementScore.pending, (state) => {
      state.isLoading = true;
      state.getScores = false;
    });
    builder.addCase(getFinancialStatementScore.fulfilled, (state, action) => {
      state.financialData = action.payload;
      state.isLoading = false;
      state.getScores = true;
    });
    builder.addCase(getFinancialStatementScore.rejected, (state) => {
      state.isLoading = false;
      state.getScores = false;
    });

    // get bank statement score card
    builder.addCase(getBankStatementScore.pending, (state) => {
      state.isLoading = true;
      state.getScores = false;
    });
    builder.addCase(getBankStatementScore.fulfilled, (state, action) => {
      state.bankData = action.payload;
      state.isLoading = false;
      state.getScores = true;
    });
    builder.addCase(getBankStatementScore.rejected, (state) => {
      state.isLoading = false;
      state.getScores = false;
    });

    // get Trexo score card
    builder.addCase(allScores.pending, (state) => {
      state.isLoading = true;
      state.getScores = false;
    });
    builder.addCase(allScores.fulfilled, (state, action) => {
      state.scoreData = action.payload;
      state.bank_scorecard = action.payload?.bank_scorecard;
      state.cibil_scorecard = action.payload?.cibil_scorecard;
      state.financial_scorecard = action.payload?.financial_scorecard;
      state.isLoading = false;
      state.getScores = true;
    });
    builder.addCase(allScores.rejected, (state) => {
      state.isLoading = false;
      state.getScores = false;
    });
    // get loan detail
    builder.addCase(getLoanDetail.pending, (state) => {
      state.isLoading = true;
      state.getScores = false;
    });
    builder.addCase(getLoanDetail.fulfilled, (state, action) => {
      console.log("action.payload", action.payload);
      state.isLoading = false;
      state.loanInfo = action.payload;
    });
    builder.addCase(getLoanDetail.rejected, (state) => {
      state.isLoading = false;
      state.getScores = false;
    });
    builder.addCase(getDocumentTypes.pending, (state) => {
      state.isLoading = true;
      state.getScores = false;
    });
    builder.addCase(getDocumentTypes.fulfilled, (state, action) => {
      console.log("action.payload", action.payload);
      state.isLoading = false;
      state.docTypes = action.payload?.data;
    });
    builder.addCase(getDocumentTypes.rejected, (state) => {
      state.isLoading = false;
      state.getScores = false;
    });

    // builder.addCase(uploadSupportedDocuments.pending, (state) => {
    //   state.isLoading = true;
    //   state.isAdded = false;
    //   state.supportedDoc = null;
    // });
    // builder.addCase(uploadSupportedDocuments.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   state.isAdded = true;
    //   state.supportedDoc = action.payload;
    // });
    // builder.addCase(uploadSupportedDocuments.rejected, (state) => {
    //   state.isLoading = false;
    //   state.isAdded = false;
    //   state.supportedDoc = null;
    // });
    // get Trexo score card
    builder.addCase(getLoanScoreCard.pending, (state) => {
      state.isLoading = true;
      state.getScores = false;
    });
    builder.addCase(getLoanScoreCard.fulfilled, (state, action) => {
      state.scoreData = action.payload;
      state.bank_scorecard = action.payload?.bank_scorecard;
      state.cibil_scorecard = action.payload?.cibil_scorecard;
      state.financial_scorecard = action.payload?.financial_scorecard;
      state.isLoading = false;
      state.getScores = true;
    });
    builder.addCase(getLoanScoreCard.rejected, (state) => {
      state.isLoading = false;
      state.getScores = false;
    });

    builder.addCase(deleteFile.pending, (state) => {
      state.isLoading = true;
      state.isDeleted = false;
    });
    builder.addCase(deleteFile.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isDeleted = action?.payload?.success;
    });
    builder.addCase(deleteFile.rejected, (state) => {
      state.isLoading = false;
      state.isDeleted = false;
    });
    builder.addCase(deleteLoan.pending, (state) => {
      state.isLoading = true;
      state.isDeleted = false;
    });
    builder.addCase(deleteLoan.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isDeleted = true;
    });
    builder.addCase(deleteLoan.rejected, (state) => {
      state.isLoading = false;
      state.isDeleted = false;
    });
    // builder.addCase(deleteLoan.pending, (state) => {
    //   state.isLoading = true;
    //   // state.isDeleted = false;
    // });
    // builder.addCase(deleteLoan.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   // state.isDeleted = action?.payload?.success;
    // });
    // builder.addCase(deleteLoan.rejected, (state) => {
    //   state.isLoading = false;
    //   // state.isDeleted = false;
    // });
  },
});

// Reducer
export default loan.reducer;

// Actions
// export const { savecustomerBasicInfoSuccess } = slice.actions;

// ----------------------------------------------------------------------
//reinit
export function resetLoading() {
  return async (dispatch) => {
    dispatch(loan.actions.requestReset());
  };
}

/**
 * Save loan document kyc
 */
export const getLoanApplication = createAsyncThunk(
  //action type string
  "loan/get-loan-application",
  // callback function
  async (loanId) => {
    const response = await axios.get(`get-loan-application/${loanId}`, {
      loanId: loanId,
    });
    return response.data;
  }
);

//Get pre qualification
export const getPreQualification = createAsyncThunk(
  //action type string
  "loan/get-pre-qualification",
  // callback function
  async (loan_Id) => {
    const response = await axios.get(`get-pre-qualification?loanId=${loan_Id}`);

    return response.data;
  }
);

//Get pre qualification document
export const getPreQualificationDocument = createAsyncThunk(
  //action type string
  "loan/get-pre-qualification-document",
  // callback function
  async (loanId) => {
    const response = await axios.get(
      `get-pre-qualification-document?loanId=${loanId}`
    );
    return response.data;
  }
);

//Get pre qualification financial
export const getPreQualificationFinancial = createAsyncThunk(
  //action type string
  "loan/get-pre-qualification-financial",
  // callback function
  async (loanId) => {
    const response = await axios.get(
      `get-pre-qualification-financial?loanId=${loanId}`
    );
    return response.data;
  }
);

//Get pre qualification financial
export const getPreCibilData = createAsyncThunk(
  //action type string
  "loan/get-cibil-statement",
  // callback function
  async (loanId) => {
    const response = await axios.get(`get-cibil-statement?loanId=${loanId}`);
    return response.data;
  }
);

//Get pre qualification bank statement
export const getPreQualificationBankStatement = createAsyncThunk(
  //action type string
  "loan/get-pre-qualification-bank-statement",
  // callback function
  async (loanId) => {
    const response = await axios.get(
      `get-pre-qualification-bank-statement?loanId=${loanId}`
    );
    return response.data;
  }
);

export const getAdditionalDocuments = (loanId) => async (dispatch) => {
  //  return;
  //
  dispatch(loan.actions.startLoading());
  try {
    const response = await axios.get(
      `get-loan-additional-documents?loanId=${loanId}`
    );
    dispatch(loan.actions.getAdditionalDocumentsSuccess(response?.data));
  } catch (error) {
    //
    dispatch(loan.actions.hasError(error));
  }
};

export const getProductDetails = (loanId) => async (dispatch) => {
  //  return;
  //
  dispatch(loan.actions.startLoading());
  try {
    const response = await axios.get(`get-loan-products?loanId=${loanId}`, {
      loanId,
    });
    dispatch(loan.actions.getProductsDetailsSuccess(response.data.data));
  } catch (error) {
    dispatch(loan.actions.hasError(error));
  }
};

//
/**
 * Save loan application
 */
// export const saveLoanApplication = createAsyncThunk(
//   //action type string
//   "loan/save-loan-application",
//   // callback function
//   async (requestData) => {
//     let response;
//     if (requestData.loanId) {
//       response = await axios.post(
//         `save-loan-application?loanId=${requestData.loanId}`,
//         requestData
//       );
//     } else {
//       response = await axios.post("save-loan-application", requestData);
//     }

//     return response.data;
//   }
// );

export const saveLoanApplication = (requestData) => async (dispatch) => {
  //  return;
  //
  dispatch(loan.actions.startLoading());
  try {
    let response;
    if (requestData?.loanId) {
      response = await axios.post(
        `save-loan-application?loanId=${requestData.loanId}`,
        requestData
      );
    } else {
      response = await axios.post("save-loan-application", requestData);
    }
    dispatch(loan.actions.saveLoanApplicationSuccess(response?.data));
  } catch (error) {
    //
    dispatch(loan.actions.hasError(error));
  }
};

/**
 * Save loan document kyc
 */
// export const saveLoanDocumentKyc = createAsyncThunk(
//   //action type string
//   "loan/save-loan-document-kyc",
//   // callback function
//   async (requestData) => {
//     const response = await axios.post(`save-loan-document-kyc`, requestData);
//     return response.data;
//   }
// );
export const saveLoanDocumentKyc = (requestData) => async (dispatch) => {
  //  return;
  //
  dispatch(loan.actions.startLoading());
  try {
    const response = await axios.post(`save-loan-document-kyc`, requestData);
    dispatch(loan.actions.saveLoanDocumentKycSuccess(response?.data));
  } catch (error) {
    //
    dispatch(loan.actions.hasError(error));
  }
};
// additional documents
export const saveLoanAdditionalDocuments =
  (requestData) => async (dispatch) => {
    //  return;
    //
    dispatch(loan.actions.startLoading());
    try {
      const response = await axios.post(
        `save-loan-additional-documents`,
        requestData
      );

      if (response?.data?.success === true) {
        dispatch(
          loan.actions.saveLoanAdditionalDocumentsSuccess(response?.data)
        );
      } else {
        dispatch(loan.actions.hasError(response?.data));
      }
    } catch (error) {
      //
      dispatch(loan.actions.hasError(error));
    }
  };

export const verifyGST = createAsyncThunk(
  //action type string
  "loan/verify-gst",
  // callback function
  async (gstNumber, dispatch) => {
    const response = await axios.post("/gst-verification", {
      gst_number: gstNumber,
    });
    return response.data;
  }
);

// export const verifyGST = (gstNumber) => async (dispatch) => {
//   //  return;
//   //
//   dispatch(loan.actions.startLoading());
//   try {
//     const response = await axios.post("verify-gst", { gst_number: gstNumber });
//     dispatch(loan.actions.verifyGSTSuccess(response?.data));
//   } catch (error) {
//     //
//     dispatch(loan.actions.hasError(error));
//   }
// };

export const verifyPAN = createAsyncThunk(
  //action type string
  "loan/verify-pan",
  // callback function
  async (panNumber, dispatch) => {
    const response = await axios.post("verify-pan", { pan_number: panNumber });
    return response.data;
  }
);
// export const verifyPAN = (panNumber) => async (dispatch) => {
//   //  return;
//   //
//   dispatch(loan.actions.startLoading());
//   try {
//     const response = await axios.post("verify-pan", { pan_number: panNumber });
//     dispatch(loan.actions.verifyPANSuccess(response?.data));
//   } catch (error) {
//     //
//     dispatch(loan.actions.hasError(error));
//   }
// };

export const loanVerificationGST = createAsyncThunk(
  //action type string
  "loan/loan-gst-verification",
  // callback function
  async (requestData, dispatch) => {
    const response = await axios.post("loan-gst-verification", requestData);
    return response.data;
  }
);

export const uploadFinancialStatement = createAsyncThunk(
  //action type string
  "loan/upload-financial-statement",
  // callback function
  async (requestData) => {
    // const response = await axios.post("upload-financial-statement", requestData, {
    const response = await axios.post("upload-financial-statement", {
      ...requestData,
    });
    return response.data;
  }
);
// export const uploadFinancialStatement = (requestData) => async (dispatch) => {
//   dispatch(loan.actions.startLoading());
//   try {
//     const response = await axios.post(`upload-financial-statement`, {
//       ...requestData,
//     });
//     dispatch(loan.actions.uploadFinancialStatementSuccess(response?.data));
//   } catch (error) {
//     //
//     dispatch(loan.actions.hasError(error));
//   }
// };

// export const uploadCibilStatement = createAsyncThunk(
//   //action type string
//   "loan/upload-cibil-statement",
//   // callback function
//   async (requestData, dispatch) => {
//     //  return;
//     // const response = await axios.post("upload-cibil-statement", requestData, {
//     const response = await axios.post("upload-cibil-statement", requestData);
//     return response.data;
//   }
// );

export const uploadCibilStatement = (requestData) => async (dispatch) => {
  dispatch(loan.actions.startLoading());
  try {
    const response = await axios.post("upload-cibil-statement", requestData);
    dispatch(loan.actions.uploadCibilStatementSuccess(response?.data));
  } catch (error) {
    //
    dispatch(loan.actions.hasError(error));
  }
};

// export const uploadBankStatement = createAsyncThunk(
//   //action type string
//   "loan/upload-bank-statement",
//   // callback function
//   async (requestData, dispatch) => {
//     const response = await axios.post("upload-bank-statement", requestData);
//     return response.data;
//   }
// );

export const uploadBankStatement = (requestData) => async (dispatch) => {
  dispatch(loan.actions.startLoading());
  try {
    const response = await axios.post("upload-bank-statement", requestData);
    dispatch(loan.actions.uploadBankStatementSuccess(response?.data));
  } catch (error) {
    //
    dispatch(loan.actions.hasError(error));
  }
};

// get cibil score card
export const getCibilScore = createAsyncThunk(
  //action type string
  "/generate-cibil-scorecard",
  // callback function
  async () => {
    const response = await axios.post("generate-cibil-scorecard");
    return response.data;
  }
);

// get Financial statement score card
export const getFinancialStatementScore = createAsyncThunk(
  //action type string
  "/generate-financial-scorecard",
  // callback function
  async () => {
    const response = await axios.post("generate-financial-scorecard");
    return response.data;
  }
);

// get bank statement score card
export const getBankStatementScore = createAsyncThunk(
  //action type string
  "/get-bank-statement-score",
  // callback function
  async () => {
    const response = await axios.post("generate-bank-scorecard");
    return response.data;
  }
);

// get Trexo score card
export const allScores = createAsyncThunk(
  //action type string
  "/get-scorecard",
  // callback function
  async () => {
    const response = await axios.post("get-scorecard");
    return response.data;
  }
);

//get bank loan offers
export const loanBankOffers = createAsyncThunk(
  //action type string
  "loan/get-bank-loan-offers",
  // callback function
  async (requestData) => {
    let response = await axios.post("get-bank-loan-offers", requestData);
    return response.data;
  }
);

//List of Loans

export const getLoanList = () => async (dispatch) => {
  dispatch(loan.actions.startLoading());
  try {
    const response = await axios.get("/get-loans");
    dispatch(loan.actions.getLoanListSuccess(response?.data?.loans));
  } catch (error) {
    //
    dispatch(loan.actions.hasError(error));
  }
};

// check pending steps
export const pendingSteps = () => async (dispatch) => {
  dispatch(loan.actions.startLoading());
  try {
    const response = await axios.get("/check-pending-loan");
    dispatch(loan.actions.pendingStepsSuccess(response?.data?.data));
  } catch (error) {
    //
    dispatch(loan.actions.hasError(error));
  }
};

export const viewFinancialStatement = (loanId) => async (dispatch) => {
  dispatch(loan.actions.startLoading());
  try {
    const response = await axios.get(
      `get-pre-qualification-financial?loanId=${loanId}`
    );
    dispatch(loan.actions.viewFinancialSuccess(response.data));
  } catch (error) {
    //
    dispatch(loan.actions.hasError(error));
  }
};

export const viewBankStatement = (loanId) => async (dispatch) => {
  dispatch(loan.actions.startLoading());
  try {
    const response = await axios.get(`get-bank-statement?loanId=${loanId}`);
    dispatch(loan.actions.viewBankSuccess(response.data));
  } catch (error) {
    //
    dispatch(loan.actions.hasError(error));
  }
};

export const viewCibilStatement = (loanId) => async (dispatch) => {
  dispatch(loan.actions.startLoading());
  try {
    const response = await axios.get(`get-cibil-statement?loanId=${loanId}`);
    dispatch(loan.actions.viewCibilSuccess(response.data));
  } catch (error) {
    //
    dispatch(loan.actions.hasError(error));
  }
};

// get loan offers
export const getLoanOffers = (loanId) => async (dispatch) => {
  dispatch(loan.actions.startLoading());
  try {
    const response = await axios.get(`get-loan-details?loanId=${loanId}`);
    dispatch(loan.actions.getLoanOffersSuccess(response.data));
  } catch (error) {
    //
    dispatch(loan.actions.hasError(error));
  }
};

// Loan ID Select
export const setTmpLoanId = (loanId) => (dispatch) => {
  dispatch(loan.actions.setTmpLoanId(loanId));
};

export const resetTmpID = (dispatch) => {
  dispatch(loan.actions.resetTmpLoanId());
};

//get bank loan offers
export const getLoanDetail = createAsyncThunk(
  //action type string
  "loan/get-loan-details",
  // callback function
  async (loanId) => {
    let response = await axios.get(`get-loan-details?loanId=${loanId}`);
    return response.data;
  }
);

//upload supported documents
// export const uploadSupportedDocuments = createAsyncThunk(
//   //action type string
//   "loan/upload-supported-documents",
//   // callback function
//   async (requestData) => {
//     let response = await axios.post(`upload-supported-documents`, {
//       ...requestData,
//     });
//     return response.data;
//   }
// );
export const uploadSupportedDocuments = (requestData) => async (dispatch) => {
  dispatch(loan.actions.startLoading());
  try {
    const response = await axios.post(`/upload-supported-documents`, {
      ...requestData,
    });
    dispatch(loan.actions.uploadSupportedDocumentsSuccess(response?.data));
  } catch (error) {
    //
    dispatch(loan.actions.hasError(error));
  }
};

// terms and conditions
export const termConditions = (loanId) => async (dispatch) => {
  dispatch(loan.actions.startLoading());
  try {
    const response = await axios.post(`/update-loan-status?loanId=${loanId}`);
    dispatch(loan.actions.termConditionsSuccess(response?.data));
  } catch (error) {
    //
    dispatch(loan.actions.hasError(error));
  }
};

// get Trexo loan score card
export const getLoanScoreCard = createAsyncThunk(
  //action type string
  "/get-loan-scorecard",
  // callback function
  async (id) => {
    const response = await axios.post(`get-loan-scorecard`, { loanId: id });
    return response.data;
  }
);

// delete file in additional document
export const deleteFile = createAsyncThunk(
  //action type string
  "/delete-loan-additional-document",
  // callback function
  async (data) => {
    const response = await axios.post(`delete-loan-additional-document`, data);
    return response.data;
  }
);

// Delete Loan
export const deleteLoan = createAsyncThunk(
  //action type string
  "/delete-loan",
  // callback function
  async (id) => {
    const response = await axios.post(`delete-loan`, { loanId: id });
    return response.data;
  }
);

// get documents types
export const getDocumentTypes = createAsyncThunk(
  //action type string
  "loan/get-document-types",
  // callback function
  async (loanId) => {
    let response = await axios.get(`get-document-types?loanId=${loanId}`);
    return response?.data;
  }
);
