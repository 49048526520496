import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import "./OffCanvasCard.css";
import CurrencyFormat from "react-currency-format";
import { currencySymbol } from "../../../utils/helpers";
import DynamicTitle from "../../Product/DynamicTitle";

const OffWishlistCanvasCard = ({ item, removeItem }) => {
  return (
    <div
      className="d-flex gap-3 my-2 align-items-center justify-content-between"
      key={item.id + item?.variant_id}
    >
        <Link
          // to={`/product-detail/${item.slug}`}
          style={{ textDecoration: "none", color: "gray" }}
          className="linkstyle d-flex flex-row gap-3 my-2"
        >
        {item?.images && (
          <div className="wishMedia">
          <img
            src={
              item?.images[0]?.url ||
              `${process.env.PUBLIC_URL}/images/placeholder-images.png`
            }
            alt=""
            className="img-fluid"
            height="71"
            width="71"
          />
          <button className="wish-delete"
           onClick={() =>
            removeItem(item.product_id, item.variant_id, item.wishlist_id)
          }
          ><i className="fas fa-trash-alt"></i></button>
          </div>
        )}
          <div className="wishcontent">
            {/* <h6>{item?.variant_type === "1"
                        ? item?.title
                        : item?.product?.trans?.name}{" "} ({item?.title}) </h6> */}
              <Link
                to={"/product-detail/" + item?.sku}
              >
              <h6>
                <DynamicTitle data={item} />
              </h6>
              </Link>
            <p>Price:&nbsp;
            {currencySymbol()}
            {item &&  <CurrencyFormat
                        value={item?.price}
                        displayType={"text"}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        decimalScale={2}
            />}
            </p>
          </div>
        </Link>
      {/* <Button variant="" className="navbar-icon pb-0 "></Button> */}
      {/* <FontAwesomeIcon
        icon={faTrashAlt}
        className="text-danger pe-auto"
        size="sm"
        style={{ color: "red", cursor: "pointer" }}
        onClick={() =>
          removeItem(item.product_id, item.variant_id, item.wishlist_id)
        }
      /> */}
    </div>
  );
};

export default React.memo(OffWishlistCanvasCard);
