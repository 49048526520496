import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  locations: [],
  divisions: [],
  categories: [],
  brands: [],
  productFeatures: [],
  variationOptions: [],
  homeCategories: [],
  countries: [],
  states: [],
  kycQuestions: [],
  globalSettings: [],
  cmsPageContent: [],
  arrFAQs: [],
  units: [],
  intitalLocationId: null,
  loanOptions: [],
  category: null,
};

const slice = createSlice({
  name: "common",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetShipping(state, action){
      state.locations = [];
    },

    //GET SHIPPING LOCATIONS
    getShippingLocationsSuccess(state, action) {
      state.isLoading = false;
      state.locations = action.payload;
    },

    // GET DIVISIONS
    getDivisionsSuccess(state, action) {
      state.isLoading = false;
      state.divisions = action.payload;
    },

    // GET HOME PAGE CATEGORIES
    getHomeCategoriesSuccess(state, action) {
      state.isLoading = false;
      state.homeCategories = action.payload;
    },

    // GET CATEGORIES
    getCategoriesSuccess(state, action) {
      state.isLoading = false;
      state.categories = action.payload;
    },
    // GET Brands
    getBrandSuccess(state, action) {
      state.isLoading = false;
      state.brands = [];
      action.payload.map((item) => {
        const obj = {
          label: item.name,
          value: item.id,
        };
        state.brands.push(obj);
      });
    },
    // GET Product Features
    getProductFeatureSuccess(state, action) {
      state.isLoading = false;
      state.productFeatures = [];
      action.payload.map((item) => {
        const obj = {
          label: item.feature_name,
          value: item.id,
          required: item?.required
        };
        state.productFeatures.push(obj);
      });
    },
    // GET VARIATION OPTIONS BY CATEGORY
    getVariationOptionsSuccess(state, action) {
      state.isLoading = false;
      state.variationOptions = action.payload;
    },
    // GET COUNTRIES
    getCountriesSuccess(state, action) {
      state.isLoading = false;
      state.countries = action.payload;
    },
    // GET STATES
    getStatesSuccess(state, action) {
      state.isLoading = false;
      state.states = action.payload;
    },
    // GET KYC QUESTIONS
    getKycQuestionsSuccess(state, action) {
      state.isLoading = false;
      state.kycQuestions = action.payload;
    },
    // GET GLOBAL SETTINGS
    getGlobalSettingsSuccess(state, action) {
      state.isLoading = false;
      state.globalSettings = action.payload;
    },
    // GET CMS PAGE CONTENT
    getCMSPageContentSuccess(state, action) {
      state.isLoading = false;
      state.cmsPageContent = action.payload;
    },
    // GET FAQS
    getFAQSSuccess(state, action) {
      state.isLoading = false;
      state.arrFAQs = action.payload;
    },
    getUnitSuccess(state, action) {
      state.isLoading = false;
      state.units = [];
      action.payload.map((item) => {
        let obj = {
          label: item,
          value: item,
        };
        state.units.push(obj);
      });
    },
    addReview: (state, action) => {
      let { data } = action.payload;
      state.reviews.push(data);
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getShippingLocations.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getShippingLocations.fulfilled, (state, action) => {
      state.isLoading = false;
      state.locations = action.payload;
    });

    builder.addCase(getShippingLocations.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getStatesAsync.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getStatesAsync.fulfilled, (state, action) => {
      state.isLoading = false;
      state.states = action.payload.states;
    });

    builder.addCase(getStatesAsync.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getLoanTypes.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getLoanTypes.fulfilled, (state, action) => {
      state.isLoading = false;
      state.loanOptions = action.payload;
    });

    builder.addCase(getLoanTypes.rejected, (state, action) => {
      state.isLoading = false;
    });
    // get category
    builder.addCase(getCategory.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCategory.fulfilled, (state, action) => {
      state.isLoading = false;
      state.category = action.payload;
    });
    builder.addCase(getCategory.rejected, (state, action) => {
      state.isLoading = false;
    });

  },
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow, deleteUser } = slice.actions;

// ----------------------------------------------------------------------

// get-shipping-locations
// export function getShippingLocations() {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post("/get-shipping-locations");
//       dispatch(
//         slice.actions.getShippingLocationsSuccess(response.data.locations)
//       );
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

export function resetShippingLocations() {
  return async (dispatch) => {
      dispatch(slice.actions.resetShipping());
  };
}


export const getShippingLocations = createAsyncThunk(
  "common/get-shipping-locations",
  async () => {
    const response = await axios.post("/get-shipping-locations");
    return response.data.locations;
  }
);

//divisions
export function getDivisions() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/get-divisions");
      dispatch(slice.actions.getDivisionsSuccess(response.data.divisions));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//categories
export function getCategories(divisionId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/get-categories", { divisionId });
      dispatch(slice.actions.getCategoriesSuccess(response.data.categories));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// Brands
export function getBrands(category_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/brands", { category_id });
      dispatch(slice.actions.getBrandSuccess(response.data.data));
      //
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Product Features
export function getProductFeatures(category_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/get-product-features", {
        category_id,
      });
      dispatch(slice.actions.getProductFeatureSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//get variation options by category
export function getVariationOptions(categoryId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/get-variation-options", {
        categoryId,
      });
      dispatch(slice.actions.getVariationOptionsSuccess(response.data.options));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//home page categories
export function getHomeCategories(divisionId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/get-home-category");
      dispatch(
        slice.actions.getHomeCategoriesSuccess(response.data.categories)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//division categories tree
export function getCategoriesTree(divisionId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/get-categories-tree", { divisionId });
      dispatch(slice.actions.getCategoriesSuccess(response.data.categories));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//division categories tree
export function getBrandTree(divisionId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/brands?category_id=", { divisionId });
      dispatch(slice.actions.getBrandSuccess(response.data.categories));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//countries
export function getCountries() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/get-countries");
      dispatch(slice.actions.getCountriesSuccess(response.data.countries));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
//states
export function getStates(countryId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/get-states", { countryId });
      dispatch(slice.actions.getStatesSuccess(response.data.states));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export const getStatesAsync = createAsyncThunk(
  "common/get-states-async",
  async (countryId) => {
    const response = await axios.post("/get-states", { countryId });
    return response.data;
  }
);

//get kyc questions
export function getKycQuestions(countryId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/get-kyc-questions", { countryId });
      dispatch(slice.actions.getKycQuestionsSuccess(response.data.questions));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//get global settings
export function getGlobalSettings() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/get-global-settings");
      dispatch(
        slice.actions.getGlobalSettingsSuccess(response.data.global_settings)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//get cms page content
export function getCMSPageContent(pageUrl) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/get-cms-page/" + pageUrl);
      //
      dispatch(slice.actions.getCMSPageContentSuccess(response.data.cms_page));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

//get cms page content
export function getFAQs(pageUrl) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/get-faqs");
      //
      dispatch(slice.actions.getFAQSSuccess(response.data.faqs));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Get Units
export function getUnits() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/get-units");
      dispatch(slice.actions.getUnitSuccess(response.data.options));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export const getLoanTypes = createAsyncThunk(
  "common/get-loan-types",
  async () => {
    const response = await axios.get("/get-loan-options");
    return response.data.data;
  }
);

// get category
export const getCategory = createAsyncThunk(
  "common/get-category",
  async (id) => {
    const response = await axios.get(`/get-category/${id}`);
    return response.data.category;
  }
);
