import axios from "axios";
import Swal from "sweetalert2";
// config
import { HOST_API } from "../config/config";

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
// );

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "/login" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        if (err.response.status === 401) {
          Swal.fire({
            icon: "error",
            // title: `${err.response.data.error}`,
            // text: `${err.response.data.error}`,
            text: `Session expired, please login again.`,
            showConfirmButton: false,
            timer: 2500,
          });
          localStorage.removeItem("accessToken");
          localStorage.clear();
          if (typeof window !== "undefined") {
            setTimeout(function(){window.location.href = "/auth/login"} , 2600);
          }
        }
      }

      //check for supplier on-boarding rejection
      if (err.response.status === 451) {
        
        Swal.fire({
          icon: "error",
          title: `${err.response.data.error}`,
          text: `${err.response.data.errorReason}`,
          showConfirmButton: false,
          timer: 2500,
        });
        // localStorage.removeItem("accessToken");
        // localStorage.clear();
        if (typeof window !== "undefined") {
          if (err.response.data.step === "1") {
            setTimeout(function(){window.location.href = "/supplier/uinformation?status=rejected"} , 3000);
          }
          if (err.response.data.step === "2") {
            setTimeout(function(){window.location.href = "/supplier/kyc?status=rejected"} , 3000);
          }
          if (err.response.data.step === "3") {
            setTimeout(function(){window.location.href = "/supplier/banking?status=rejected"} , 3000);
          }
          if (err.response.data.step === "4") {
            setTimeout(function(){window.location.href = "/supplier/verification?status=rejected"} , 3000);
          }
          if (err.response.data.step === "5") {
            setTimeout(function(){window.location.href = "/supplier/documentation?status=rejected"} , 3000);
          }
          if (err.response.data.step === "6") {
            setTimeout(function(){window.location.href = "/supplier/signature?status=rejected"} , 3000);
          }
        }
      }
      

    }

    return Promise.reject(
      (err.response && err.response.data) || "Something went wrong"
    );
  }
);

// axiosInstance.interceptors.response.use(
//   (res) => {
//     return res;
//   },
//   async (err) => {
//     const { validateLogin } = useAuth();
//     const originalConfig = err.config;

//     if (originalConfig.url !== "/login" && err.response) {
//       // Access Token was expired
//       if (err.response.status === 401 && !originalConfig._retry) {
//         originalConfig._retry = true;

//         try {
//           await validateLogin();

//           return axiosInstance(originalConfig);
//         } catch (_error) {
//           return Promise.reject(_error);
//         }
//       }
//     }

//     return Promise.reject(err);
//   }
// );

export default axiosInstance;
