import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const SupplierQuotesSlice = createSlice({
  name: "supplierQuotes",
  initialState: {
    isLoading: false,
    quote: null,
    quotes: [],
    agreeLoading: false,
  },
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // Reset LOADING
    requestReset(state) {
      state.quote = null;
      state.error = null;
    },

    cleanQuotes(state) {
      state.quotes = null;
      state.quote = null;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // LIST SUPPLIERS QUOTES
    listSupplierQuotesSuccess(state, action) {
      state.isLoading = false;
      state.quotes = action.payload;
      state.quote = null;
    },
    // GET SUPPLIER QUOTES
    getSupplierQuoteSuccess(state, action) {
      state.isLoading = false;
      state.quote = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(agreeForCustomer.pending, (state) => {
      state.agreeLoading = true;
    });
    builder.addCase(agreeForCustomer.fulfilled, (state, action) => {
      state.agreeLoading = false;
    });
    builder.addCase(agreeForCustomer.rejected, (state, action) => {
      state.agreeLoading = false;
    });
  },
});

//Actions
//reinit
export function resetLoading() {
  return async (dispatch) => {
    dispatch(SupplierQuotesSlice.actions.requestReset());
  };
}

export function supplierQuoteCleanup() {
  return async (dispatch) => {
    dispatch(SupplierQuotesSlice.actions.cleanQuotes());
  };
}

//list SupplierQuotes
export const listSupplierQuotes = () => async (dispatch) => {
  //  return;
  //
  dispatch(SupplierQuotesSlice.actions.startLoading());
  try {
    const response = await axios.post("/supplier/orders");
    dispatch(
      SupplierQuotesSlice.actions.listSupplierQuotesSuccess(
        response.data.orders
      )
    );
  } catch (error) {
    //
    dispatch(SupplierQuotesSlice.actions.hasError(error));
  }
};

//get supplier quote
export const getSupplierQuote = (quoteId, supplierId) => async (dispatch) => {
  dispatch(SupplierQuotesSlice.actions.startLoading());
  try {
    if (
      supplierId !== null &&
      supplierId !== undefined &&
      supplierId !== "" &&
      quoteId !== null &&
      quoteId !== undefined &&
      quoteId !== ""
    ) {
      const response = await axios.post("/view-supplier-quotes-detail", {
        id: quoteId,
        supplierId,
      });
      dispatch(
        SupplierQuotesSlice.actions.getSupplierQuoteSuccess(response.data.quote)
      );
    }
  } catch (error) {
    dispatch(SupplierQuotesSlice.actions.hasError(error));
  }
};

export const agreeForCustomer = createAsyncThunk(
  "supplierQuotes/agree-for-customer",
  async ({ customer_id, supplier_id, quote_id }) => {
    const response = await axios.post(`/agree-counter-offer`, {
      customer_id,
      supplier_id,
      quote_id,
    });
    return response.data;
  }
);

const supplierQuotesReducer = SupplierQuotesSlice.reducer;
export default supplierQuotesReducer;
