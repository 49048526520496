// import "bootstrap/dist/css/bootstrap.min.css";

import React, { lazy, Suspense } from "react";
// import ReactDOM from "react-dom/client";
import ReactDOM from "react-dom";
// import 'font-awesome/css/font-awesome.min.css'

import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import ScrollToTop from "./components/ScrollToTop";

// redux
import { store, persistor } from "./redux/store";

// contexts
import { AuthProvider } from "./contexts/JWTContext";

import NoInternetConnection from "./NoInternetConnection";

// import App from "./App";

// import Custom Css
import "./assets/css/style.css";
import "./assets/css/custom.css";
import "./assets/css/color.css";
import "./assets/css/responsive.css";
import "./assets/css/animate.min.css";
import LoadingScreen from "./components/LoadingScreen";
import { PlaceOrderProvider } from "./contexts/PlaceOrderContext";
const App = lazy(() => import("./App"));
// import reportWebVitals from "./reportWebVitals";

// create a root
// const root = ReactDOM.createRoot(document.getElementById("root"));

//render app to root
// root.render(
//   <HelmetProvider>
//     <ReduxProvider store={store}>
//       <PersistGate loading={null} persistor={persistor}>
//         <BrowserRouter>
//           <ScrollToTop>
//             <AuthProvider>
//               <App />
//             </AuthProvider>
//           </ScrollToTop>
//         </BrowserRouter>
//       </PersistGate>
//     </ReduxProvider>
//   </HelmetProvider>
// );

ReactDOM.render(
  // <NoInternetConnection>
    <Suspense fallback={<LoadingScreen />}>
      <HelmetProvider>
        <ReduxProvider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <ScrollToTop>
                <AuthProvider>
                <PlaceOrderProvider>
                  <App />
                </PlaceOrderProvider> 
                </AuthProvider>
              </ScrollToTop>
            </BrowserRouter>
          </PersistGate>
        </ReduxProvider>
      </HelmetProvider>
    </Suspense>
  // </NoInternetConnection>
  ,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
