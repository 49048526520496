import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";

const initialState = {
  isLoading: false,
  error: false,
  pageData: null,
  basicInfo: null,
  kycInfo: null,
  bankingInfo: null,
  verificationInfo: null,
  documentsInfo: null,
  signatureInfo: null,
  isSaved: false,
  isLocationSaved: false,
  isLocationDelete : false,
  locationData: null,
  wishlist: null,
  wishlists: [],
  wishlistsForContext: [],
  wishlistsForContextCount: [],
  compareForContextData: [],
  wishlistProductIds: [],
  isWishlistDeleted: false,
  isWishlistAdded: false,
  wishlistAddMsg: "",
  wishlistDelMsg: "",
  isWishlistServerActiontatus: false,
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.isSaved = false;
    },
    // Reset LOADING
    requestReset(state) {
      state.isSaved = false;
      state.pageData = null;
      state.error = null;
      state.isLocationSaved = false;
      state.isLocationDelete = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.isSaved = false;
    },

    // GET STEP STATUS
    getStepStatusSuccess(state, action) {
      state.isLoading = false;
      state.isSaved = false;
      state.pageData = action.payload;
    },
    // SAVE SUPPLIER BASIC INFO
    saveSupplierBasicInfoSuccess(state, action) {
      state.isLoading = false;
      state.basicInfo = action.payload;
      state.isSaved = true;
    },
    // SAVE SUPPLIER KYC
    saveSupplierKycSuccess(state, action) {
      state.isLoading = false;
      state.kycInfo = action.payload;
      state.isSaved = true;
    },
    // SAVE SUPPLIER KYC
    saveBankingInfoSuccess(state, action) {
      state.isLoading = false;
      state.bankingInfo = action.payload;
      state.isSaved = true;
    },
    // SAVE SUPPLIER VERIFICATION
    saveSupplierVerificationSuccess(state, action) {
      state.isLoading = false;
      state.verificationInfo = action.payload;
      state.isSaved = true;
    },
    // SAVE SUPPLIER DOCUMENTATION
    saveSupplierDocumentationSuccess(state, action) {
      state.isLoading = false;
      state.documentsInfo = action.payload;
      state.isSaved = true;
    },
    // SAVE SUPPLIER SIGNATURE
    saveSupplierSignatureSuccess(state, action) {
      state.isLoading = false;
      state.signatureInfo = action.payload;
      state.isSaved = true;
    },
    // SAVE SUPPLIER LOCATION INFO
    saveSupplierLocationSuccess(state, action) {
      state.isLoading = false;
      state.locationData = action.payload;
      state.isLocationSaved = true;
    },

    // GET COMPARE PRODUCTS
    getCompareProductIdsSuccess(state, action) {
      state.isLoading = false;
      state.compareForContextData = action.payload;
    },

    // GET USER BASIC INFO
    getBasicInfoSuccess(state, action) {
      state.isLoading = false;
      state.basicInfo = action.payload;
      state.isSaved = false;
    },
    // UPDATE USER BUSINESS INFO
    updateBusinessSuccess(state, action) {
      state.isLoading = false;
      // state.userInfo = action.payload;
      state.isSaved = true;
    },

    // Delete Supplier location
    deleteSupplierLocationSuccess(state, action){
      state.isLoading = false;
      state.isLocationDelete = true;
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const { saveSupplierBasicInfoSuccess } = slice.actions;
//reinit
export function resetLoading() {
  return async (dispatch) => {
    dispatch(slice.actions.requestReset());
  };
}
//step status
export function getStepStatus() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/check-pending-step");
      dispatch(slice.actions.getStepStatusSuccess(response.data));
    } catch (error) {
      // 
      dispatch(slice.actions.hasError(error));
    }
  };
}

//save supplier info
export const saveSupplierBasicInfo = (userData) => async (dispatch) => {
  //  return;
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post("/save-supplier-basic-info", userData);
    dispatch(
      slice.actions.saveSupplierBasicInfoSuccess(response.data.basicInfo)
    );
  } catch (error) {
    // 
    dispatch(slice.actions.hasError(error));
  }
};
//update supplier info
export const updateSupplierBasicInfo = (userData) => async (dispatch) => {
  //  return;
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post("/update-supplier-basic-info", userData);
    dispatch(
      slice.actions.saveSupplierBasicInfoSuccess(response.data.basicInfo)
    );
  } catch (error) {
    // 
    dispatch(slice.actions.hasError(error));
  }
};

//submit supplier kyc
export const saveSupplierKyc = (userData) => async (dispatch) => {
  //  return;
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post("/save-supplier-kyc", userData);
    dispatch(slice.actions.saveSupplierKycSuccess(response.data.kycInfo));
  } catch (error) {
    
    dispatch(slice.actions.hasError(error));
  }
};

//submit supplier kyc
export const saveBankingInfo = (bankingData) => async (dispatch) => {
  //  return;
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      "/save-supplier-banking-info",
      bankingData
    );
    dispatch(slice.actions.saveBankingInfoSuccess(response.data.bankingInfo));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

//submit supplier verification
export const saveSupplierVerification = (bankingData) => async (dispatch) => {
  //  return;
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post(
      "/save-supplier-verification-info",
      bankingData
    );
    dispatch(
      slice.actions.saveSupplierVerificationSuccess(
        response.data.verificationInfo
      )
    );
  } catch (error) {
    
    dispatch(slice.actions.hasError(error));
  }
};

//submit supplier documentation
export const saveSupplierDocumentation = ({bankingData}) => async (dispatch) => {
  //  return;
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post("/save-supplier-documents", bankingData);
    dispatch(
      slice.actions.saveSupplierDocumentationSuccess(
        response.data.documentsInfo
      )
    );
  } catch (error) {
    
    dispatch(slice.actions.hasError(error));
  }
};

//submit supplier signature
export const saveSupplierSignature = (bankingData) => async (dispatch) => {
  //  return;
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post("/save-supplier-signature", bankingData);
    dispatch(
      slice.actions.saveSupplierSignatureSuccess(response.data.signatureInfo)
    );
  } catch (error) {
    
    dispatch(slice.actions.hasError(error));
  }
};

//save supplier location
export const saveSupplierLocation = (userData, user_id) => async (dispatch) => {
  //  return;
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post("/save-supplier-loaction-info", userData);
    dispatch(slice.actions.saveSupplierLocationSuccess(response.data));

  } catch (error) {
    
    dispatch(slice.actions.hasError(error));
  }
};
//delete supplier location
export const deleteSupplierLocation = (location_id) => async (dispatch) => {
  //  return;
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.post("/delete-supplier-loaction-info", {location_id : location_id});
    dispatch(slice.actions.deleteSupplierLocationSuccess(response.data));

  } catch (error) {
    
    dispatch(slice.actions.hasError(error));
  }
};



//add product to compare
export const addToCompare = (product) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    dispatch(slice.actions.requestReset());
    dispatch(slice.actions.getCompareProductIdsSuccess(product));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

//get business
export const getBasicInfo = () => async (dispatch) => {
  //  return;
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.get("/get-basic-info");
    dispatch(slice.actions.getBasicInfoSuccess(response?.data?.basicInfo));

  } catch (error) {
    
    dispatch(slice.actions.hasError(error));
  }
};
//update customer/supplier business
export const updateBusiness = (userData) => async (dispatch) => {
  //  return;
  dispatch(slice.actions.startLoading());
  try {
    const response = await axios.put("/update-business", userData);
    dispatch(slice.actions.updateBusinessSuccess(response.data));

  } catch (error) {
    
    dispatch(slice.actions.hasError(error));
  }
};