import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
// Demo Data
import { ProductData } from "../../app/data/productsData";
// Alert
import Swal from "sweetalert2";
import { WishListShow } from "./../../components/OffCanvas/WishList/WishListShow";

// Product Slice
const productsSlice = createSlice({
  name: "products",
  initialState: {
    isLoading: false,
    product: null,
    products: ProductData,
    // carts: ProductData.slice(4, 7),
    // favorites: ProductData.slice(8, 12),
    // compare: ProductData.slice(0, 2),
    // products: [],
    categories: [],
    variations: [],
    resultPerPage: 12,
    productsCount: 0,
    filteredProductsCount: 0,
    // carts: ProductData.slice(4, 7),
    carts : [],
    // favorites: ProductData.slice(8, 12),
    favorites: [],
    // compare: ProductData.slice(0, 2),
    single: null,
    subtotal : 0,
    totalPages : null
  },
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // Reset LOADING
    requestReset(state) {
      state.product = null;
      state.error = null;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.product = null;
    },
    // LIST PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.totalPages = action.payload?.products?.last_page
      state.products = action.payload?.products?.data;
      state.categories = action.payload?.categories;
      state.variations = action.payload?.variations;
      // state.products = action.payload;
      state.resultPerPage = action.payload?.resultPerPage;
      state.productsCount = action.payload?.productsCount;
      state.filteredProductsCount = action.payload?.filteredProductsCount;
      state.product = null;
    },
    // PRODUCT DETAIL
    getProductDetailSuccess(state, action) {
      state.isLoading = false;
      action.payload.ratings.sort((a,b) => a.id < b.id ? 1 : -1);
      state.product = action.payload;
    },

    // Get Single Product
    getProductById: (state, action) => {
      let { id } = action.payload;
      let arr = state.products.find((item) => item.id === parseInt(id));
      state.single = arr;
    },

    increseQuantity : (state, action) => {
      let {id} = action.payload;
      let item = state.carts.find((i) => i.id === parseInt(id));
      item.quantity += 1
      // Increase total when quantity incrseases by one
      item.total += parseInt(item.price)
      state.subtotal += parseInt(item.price)
    },
    decreaseQuantity : (state, action) => {

      let {id} = action.payload;
      let item = state.carts.find((i) => i.id === parseInt(id));
      item.quantity -= 1
      // Decrese total when quantity decreases by one
      item.total -= item.price
      state.subtotal -= parseInt(item.price)
    },

    // Add to Cart
    addToCart: (state, action) => {
      let { id } = action.payload;

      // Check existance
      let item = state.carts.find((i) => i.id === parseInt(id));
      if (item === undefined) {
        // Get Product
        let arr = state.products.find((item) => item.id === parseInt(id));
        arr.quantity = 1;
        // Item total with quantity
        arr.total = parseInt(arr.price);
        state.subtotal += parseInt(arr.price);
        state.carts.push(arr);
        Swal.fire({
          title: "Success!",
          text: "Successfully added to your Cart",
          icon: "success",
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        item.quantity += 1
      // Increase total when quantity incrseases by one
      item.total += parseInt(item.price)
      state.subtotal += parseInt(item.price)
        Swal.fire({
          title: "Quantity Increased",
          text: "This product is already added in your Cart",
          imageUrl: item.img,
          imageWidth: 200,
          imageAlt: item.title,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    },
    // Add to Compare
    addToComp: (state, action) => {
      if (state.compare.length >= 3) {
        Swal.fire({
          title: "Failed!",
          text: "Compare List is Full",
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }

      let { id } = action.payload;

      // Check existance
      let item = state.compare.find((i) => i.id === parseInt(id));
      if (item === undefined) {
        // Get Product
        let arr = state.products.find((item) => item.id === parseInt(id));
        state.compare.push(arr);
        Swal.fire({
          title: "Success!",
          text: "Successfully added to Compare List",
          icon: "success",
          showConfirmButton: false,
          timer: 2500,
        });
      } else {

        Swal.fire({
          title: "Failed!",
          text: "Already Added in Compare List",
          imageUrl: item.img,
          imageWidth: 200,
          imageAlt: item.title,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    },
    // Update Cart
    updateCart: (state, action) => {
      let { val, id } = action.payload;
      state.carts.forEach((item) => {
        if (item.id === parseInt(id)) {
          item.quantity = val;
        }
      });
    },
    // Remove Cart
    removeCart: (state, action) => {
      let { id } = action.payload;
      let item = state.carts.find((item) => item.id === parseInt(id));
      state.subtotal -= item.total;
      let arr = state.carts.filter((item) => item.id !== parseInt(id));
      state.carts = arr;
    },
    // Delete from Compare
    delCompare: (state, action) => {
      let { id } = action.payload;
      let arr = state.compare.filter((item) => item.id !== parseInt(id));
      state.compare = arr;
    },
    // Clear Cart
    clearCart: (state) => {
      state.carts = [];
    },
    // Add to Favorite / Wishlist
    addToFav: (state, action) => {
      let { id } = action.payload;

      // Check existance
      let item = state.favorites.find((i) => i.id === parseInt(id));

      if (item === undefined) {
        // Get Product
        let arr = state.products.find((item) => item.id === parseInt(id));
        arr.quantity = 1;
        state.favorites.push(arr);
        Swal.fire("Success", "Added to Wishlist", "success");
      } else {
        Swal.fire("Failed", "Already Added in Wishlist", "warning");
      }
    },
    // Add to Wishlist
    addToWishlist: (state, action) => {
      let { id } = action.payload;

      /*
      // Check existance
      let item = state.favorites.find((i) => i.id === parseInt(id));

      if (item === undefined) {
        // Get Product
        let arr = state.products.find((item) => item.id === parseInt(id));
        arr.quantity = 1;
        state.favorites.push(arr);
        Swal.fire("Success", "Added to Wishlist", "success");
      } else {
        Swal.fire("Failed", "Already Added in Wishlist", "warning");
      }*/
    },
    // Remove from Favorite / Wishlist
    removeFav: (state, action) => {
      let { id } = action.payload;
      let arr = state.favorites.filter((item) => item.id !== id);
      state.favorites = arr;
    },
  },
});

const productsReducer = productsSlice.reducer;
export default productsReducer;

//Actions
//list products
export const getProducts =
  (
    mainCategory = "",
    keyword = "",
    category,
    // price = [0, 100000],
    minPrice,
    maxPrice,
    variantOptions,
    ratings = 0,
    sortBy = "latest",
    currentPage = 1
  ) =>
  async (dispatch) => {
    //  return;
    dispatch(productsSlice.actions.startLoading());
    try {
      // let url = `/products?mainCategory=${mainCategory}&keyword=${keyword}&price[gte]=${minPrice}&price[lte]=${maxPrice}&ratings[gte]=${ratings}&page=${currentPage}`;
      // let url = `/products?mainCategory=${mainCategory}&keyword=${keyword}&minPrice=${price[0]}&maxPrice=${price[1]}&ratings[gte]=${ratings}&page=${currentPage}`;
      let url = `/products?mainCategory=${mainCategory}&keyword=${keyword}&minPrice=${minPrice}&maxPrice=${maxPrice}&variantOptions=${variantOptions}&ratings[gte]=${ratings}&_sort=${sortBy}&page=${currentPage}`;
      if (category) {
        // url = `/products?mainCategory=${mainCategory}&keyword=${keyword}&category=${category}&minPrice=${price[0]}&maxPrice=${price[1]}&ratings[gte]=${ratings}&page=${currentPage}`;
        url = `/products?mainCategory=${mainCategory}&keyword=${keyword}&category=${category}&minPrice=${minPrice}&maxPrice=${maxPrice}&variantOptions=${variantOptions}&ratings[gte]=${ratings}&_sort=${sortBy}&page=${currentPage}`;
      }

      const response = await axios.get(url);
      // 
      dispatch(productsSlice.actions.getProductsSuccess(response.data));
    } catch (error) {
      // 
      dispatch(productsSlice.actions.hasError(error));
    }
  };

//get product
export const getProductDetail = (productSlug) => async (dispatch) => {
  dispatch(productsSlice.actions.startLoading());
  try {
    const response = await axios.get(`/product-detail/${productSlug}`);
    dispatch(productsSlice.actions.requestReset());
    dispatch(
      productsSlice.actions.getProductDetailSuccess(response.data.product)
    );
  } catch (error) {
    dispatch(productsSlice.actions.hasError(error));
  }
};

export const getProductDetailWithVariant =
  (id, options) => async (dispatch) => {
    dispatch(productsSlice.actions.startLoading());
    try {
      let optionsData = JSON.parse(options);
      console.log('optionsData', optionsData)
      const response = await axios.post(
        `/get-product-detail/${id}`,
        {
          options: optionsData,
        },
        // {
        //   headers: {
        //     "content-type": "application/json",
        //   },
        // }
      );
      dispatch(productsSlice.actions.requestReset());
      dispatch(
        productsSlice.actions.getProductDetailSuccess(response.data.product)
      );
    } catch (error) {
      dispatch(productsSlice.actions.hasError(error));
    }
  };
