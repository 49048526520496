// ----------------------------------------------------------------------

import { post } from "jquery";
import { useEffect, useRef } from "react";
import { date } from "yup";

const lastFourDigits = (number) => {
  if (!number) {
    return false;
  }
  // ----------------------------------------------------------------------
  let string = String(number);
  let sliced = string.slice(-4);
  let mask = String(sliced).padStart(string.length, "x");
  return mask;
};

const dataType64toFile = (dataurl, filename = "NewFile") => {
  //Convert base64 to file
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  let newFile = new File([u8arr], filename, {
    type: mime,
  });
  return newFile;
};

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  });

// ✅ Get percentage Increase / Decrease
const getPercentage = (profit, sellPrice) => {
  return ((profit / sellPrice) * 100).toFixed(2);
};

const generateVariants = (
  options,
  variants,
  price,
  comparePrice,
  costPerItem,
  sku,
  inventoryQty
) => {
  let productPrice = price;
  //
  // let keys = options.map(({ optionName }) => optionName);
  // let values = options.map(({ optionValues }) => optionValues);

  let keys = options.map(({ id, name }) => name).concat("price");
  if (
    options.length > 0 &&
    options[0]?.optionValues !== undefined &&
    options[0]?.optionValues.length > 0
  ) {
    let values = options
      .filter(function (item) {
        if (
          item?.optionValues === undefined ||
          item?.optionValues?.length === 0
        ) {
          return false; // skip
        }
        return true;
      })
      .map(({ optionValues }) => optionValues)
      .concat([[productPrice]]);
    // let variantAttributes =
    //   values.length > 0 &&
    //   values
    //     .reduce((a, b) =>
    //       a.reduce((r, v) => r.concat(b.map((w) => [].concat(v, w))), [])
    //     )
    //     .map((a) =>
    //       Object.assign(
    //         { title: a.slice(0, -1).join(" / ") },
    //         ...keys.map((k, i) => ({ [k]: a[i] }))
    //       )
    //     );

    var variantAttributes =
      values?.length > 0 &&
      values
        .reduce((a, b) =>
          a.reduce((r, v) => r.concat(b.map((w) => [].concat(v, w))), [])
        )
        .map((a) => {
          return Object.assign(
            // { title: a.slice(0, -1).join(" / ") },
            { title: a.slice(0, -1).join(", ") },
            // { title: a.slice(0, -1).join("-") },
            ...keys.map((k, i) => ({ [k]: a[i] }))
          );
        });

    if (variantAttributes.length > 0) {
      variantAttributes = variantAttributes.map((vItem, i) => {
        return {
          ...vItem,
          price:
            variants?.length > 0 && variants[i]?.price
              ? variants[i]?.price
              : productPrice,
          compare_at_price:
            variants?.length > 0 && variants[i]?.compare_at_price
              ? variants[i]?.compare_at_price
              : comparePrice,
          cost_per_item:
            variants?.length > 0 && variants[i]?.cost_per_item
              ? variants[i]?.cost_per_item
              : costPerItem,
          // qty: variants.length > 0 && variants[i]?.qty ? variants[i]?.qty : 0,
          // inventory: [
          //   {...inventoryQty, locationQty: variants.length > 0 && variants[i]?.qty ? variants[i]?.qty : 0}
          // ],

          // inventories: inventoryQty.map((s, index) => ({
          //   ...s,
          //   available_qty:
          //     variants.length > 0 && variants[i]?.available_qty
          //       ? variants[i]?.available_qty
          //       : s.available_qty,
          // })),

          // inventories: inventoryQty.map((s, index) => {
          //   let qty = variants[i]?.inventories.find((item) => {
          //     if (item.location_id === s.id) {
          //       return item.available_qty;
          //     }
          //   });
          inventories: inventoryQty.map((s, index) => {
            let qty =
              variants?.length > 0 &&
              variants[i]?.inventories.find((item) => {
                if (item.id === s.id || item?.location_id === s.id) {
                  return item.available_qty;
                }
              });
            //
            return {
              ...s,
              available_qty:
                variants?.length > 0 &&
                variants[i]?.inventories !== undefined &&
                variants[i]?.inventories.length > 0
                  ? qty?.available_qty
                  : s.available_qty,
            };
          }),
          sku:
            variants?.length > 0 && variants[i]?.sku
              ? variants[i]?.sku
              : `${sku}-${i + 1}`,
          images:
            variants?.length > 0 && variants[i]?.images
              ? variants[i]?.images
              : [],
        };
      });
    }
  }
  return variantAttributes;
};

/**
 * Genarate variant for update page
 * @param {*} options
 * @param {*} variants
 * @param {*} price
 * @param {*} comparePrice
 * @param {*} costPerItem
 * @param {*} sku
 * @param {*} inventoryQty
 * @returns
 */
const generateVariantsUpdate = (
  options,
  variants,
  price,
  comparePrice,
  costPerItem,
  sku,
  inventoryQty,
  existingVariants
) => {
  let productPrice = price;

  let variantAttributes = [];

  let keys = options.map(({ id, name }) => name).concat("price");

  // if (
  //   options.length > 0 &&
  //   options[0]?.optionValues !== undefined &&
  //   options[0]?.optionValues.length > 0
  // ) {
  let values = options
    .filter(function (item) {
      if (
        item?.optionValues === undefined ||
        item?.optionValues?.length === 0
      ) {
        return false; // skip
      }
      return true;
    })
    .map(({ optionValues }) => optionValues)
    .concat([[productPrice]]);

  //
  variantAttributes =
    values?.length > 1 &&
    values
      .reduce((a, b) =>
        a.reduce((r, v) => r.concat(b.map((w) => [].concat(v, w))), [])
      )
      .map((a) => {
        return Object.assign(
          // { title: a.slice(0, -1).join(" / ") },
          { title: a.slice(0, -1).join(", ") },
          ...keys.map((k, i) => ({ [k]: a[i] }))
        );
      });
  if (variantAttributes.length > 0) {
    variantAttributes = variantAttributes.map((vItem, i) => {
      // let objExistingVariant = existingVariants?.find(
      //   (v, iK) => v.sku === variants[iK].sku
      // );
      let objVariant = variants.find(
        (v) =>
          v.title === vItem.title && (v.deleted === 1 || v.deleted === true)
      );
      return {
        ...vItem,
        price:
          variants.length > 0 && variants[i]?.price
            ? variants[i]?.price
            : productPrice,
        compare_at_price:
          variants.length > 0 && variants[i]?.compare_at_price
            ? variants[i]?.compare_at_price
            : comparePrice,
        cost_per_item:
          variants.length > 0 && variants[i]?.cost_per_item
            ? variants[i]?.cost_per_item
            : costPerItem,
        inventories:
          inventoryQty?.length > 0 &&
          inventoryQty?.map((s, index) => {
            let qty = variants[i]?.inventories.find((item) => {
              if (item.id === s.id || item?.location_id === s.id) {
                return item.available_qty;
              }
            });
            return {
              ...s,
              available_qty:
                variants.length > 0 &&
                variants[i]?.inventories !== undefined &&
                variants[i]?.inventories.length > 0
                  ? qty?.available_qty
                  : s.available_qty,
            };
          }),
        sku:
          variants.length > 0 && variants[i]?.sku
            ? variants[i]?.sku
            : `${sku}-${i + 1}`,
        images:
          variants.length > 0 && variants[i]?.images ? variants[i]?.images : [],
        id: variants.length > 0 && variants[i]?.id ? variants[i]?.id : "",
        deleted: objVariant !== undefined && objVariant !== null ? true : false,
        // variants.length > 0 && variants[i]?.deleted ? true : false,
        // variants[i].sku === vItem.sku > 0 ? true : false,
      };
    });
  }
  // }
  return variantAttributes;
};

const checkIfDuplicateExists = (arr) => {
  return new Set(arr).size !== arr.length;
};

const convertToSlug = (Text) => {
  return Text.trim()
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
};

const currencySymbol = () => {
  return "₹";
};

const DateFormat = (props) => {
  //
  const review_date = new Date(props.datestring);
  let hours = review_date.getHours();
  let dateString =
    ("0" + review_date.getDate()).slice(-2) +
    "-" +
    ("0" + (review_date.getMonth() + 1)).slice(-2) +
    "-" +
    review_date.getFullYear();
  let timeString =
    ("0" + (parseInt(hours) !== 12 ? parseInt(hours) % 12 : hours)).slice(-2) +
    ":" +
    ("0" + review_date.getMinutes()).slice(-2) +
    ":" +
    ("0" + review_date.getSeconds()).slice(-2);
  let meridium = hours >= 12 ? "PM" : "AM";

  const fullDate = dateString + " " + timeString + " " + meridium;
  return <span>{fullDate}</span>;
};

const DateFormatTable = (props) => {
  const review_date = new Date(props.datestring);
  let hours = review_date.getHours();
  let dateString =
    ("0" + review_date.getDate()).slice(-2) +
    "-" +
    ("0" + (review_date.getMonth() + 1)).slice(-2) +
    "-" +
    review_date.getFullYear();
  let timeString =
    ("0" + (parseInt(hours) !== 12 ? parseInt(hours) % 12 : hours)).slice(-2) +
    ":" +
    ("0" + review_date.getMinutes()).slice(-2) +
    ":" +
    ("0" + review_date.getSeconds()).slice(-2);
  let meridium = hours >= 12 ? "PM" : "AM";

  const fullDate = dateString + " " + timeString + " " + meridium;
  return (
    <>
      <span>{fullDate.slice(0, 10)}</span>
      <br />
      <span style={{ fontSize: "16px" }}>{fullDate.slice(10, 22)}</span>
    </>
  );
};
const OrderTimeFormat = (props) => {
  const review_date = new Date(props.datestring);
  let hours = review_date.getHours();
  let timeString = hours + ":" + review_date.getMinutes();
  let meridium = hours >= 12 ? "PM" : "AM";

  const fullDate = timeString + " " + meridium;
  return <span>{fullDate}</span>;
};
const OrderListDateTimeFormat = (props) => {
  const review_date = new Date(props.datestring);
  let hours = parseInt(review_date.getHours());
  if (hours !== 12) {
    hours = hours % 12;
  }
  let twelve_hours = review_date.getHours();
  let dateString =
    ("0" + review_date.getDate()).slice(-2) +
    "-" +
    ("0" + (review_date.getMonth() + 1)).slice(-2) +
    "-" +
    review_date.getFullYear();
  // let dateString = review_date.getDate() +"/" + parseInt(review_date.getMonth() + 1) + "/" + review_date.getFullYear();
  let timeString =
    ("0" + hours).slice(-2) +
    ":" +
    ("0" + review_date.getMinutes()).slice(-2) +
    ":" +
    review_date.getSeconds();
  let meridium = twelve_hours >= 12 ? "PM" : "AM";

  const fullDate = dateString + " " + timeString + " " + meridium;
  return <span>{fullDate}</span>;
};

const dateReverseFormat = (data) => {
  if (data !== null && data !== undefined && data !== "") {
    let dateStr = data; // The date string to convert
    let parts = dateStr.split("-"); // Split the date string into an array of year, month, and day components

    let day = parts[2]; // Extract the day component
    let month = parts[1]; // Extract the month component
    let year = parts[0]; // Extract the year component

    return `${day}-${month}-${year}`;
  } else {
    return "";
  }
};
const OrderDateFormat = ({ datestring }) => {
  var days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  //
  const review_date = new Date(datestring);
  let dateString =
    days[review_date.getDay()] +
    ", " +
    review_date.getDate() +
    " " +
    monthNames[review_date.getMonth()] +
    " " +
    review_date.getFullYear();

  const fullDate = dateString;
  return <span>{fullDate}</span>;
};
const isEqual = (arr1, arr2, field) => {
  return (
    arr1.length === arr2.length && // same length and
    arr1.every(
      // every element in a
      (e1) =>
        arr2.some(
          // has a match in b
          (e2) => e1?.[field] === e2?.[field]
        )
    )
  );
};

const LoanDate = (props) => {
  const review_date = new Date(props.datestring);

  let dateString =
    ("0" + review_date.getDate()).slice(-2) +
    "-" +
    ("0" + (review_date.getMonth() + 1)).slice(-2) +
    "-" +
    review_date.getFullYear();

  return <span>{dateString}</span>;
};

const capitalizeFirstLetter = (string) => {
  if (string != null || string !== undefined) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else {
    return "--";
  }
};

const NameFormattor = ({ user }) => {
  let name = "";
  if (user !== "" || user !== undefined || user != null) {
    name =
      user.first_name.charAt(0).toUpperCase() +
      user.first_name.substr(1).toLowerCase() +
      " " +
      user.last_name.charAt(0).toUpperCase() +
      user.last_name.substr(1).toLowerCase();
  }

  return name;
};

const isAvailable = (inventories, expected_quantity) => {
  if (inventories.length <= 0) {
    return false;
  }
  let allTotal = 0;
  let count = inventories.length;
  let isOk = inventories.every((item) => {
    if (count > 0) {
      if (item.available_qty <= 0) {
        count = count - 1;
      }
      if (count === 0) {
        return false;
      } else {
        allTotal += item.available_qty;
        return true;
      }
    } else {
      return false;
    }
  });

  if (expected_quantity > allTotal) {
    return false;
  }
  return isOk;
};

// Click outside handler
// OutSide Click Handler
let useClickOutside = (handler) => {
  let domNode = useRef();

  useEffect(() => {
    let maybeHandler = (event) => {
      if (!domNode?.current?.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", maybeHandler);

    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};

let isUrl = (s) => {
  var regexp =
    /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
  return regexp.test(s);
};

export {
  useClickOutside,
  lastFourDigits,
  dataType64toFile,
  getBase64,
  isUrl,
  getPercentage,
  generateVariants,
  generateVariantsUpdate,
  checkIfDuplicateExists,
  convertToSlug,
  currencySymbol,
  DateFormat,
  DateFormatTable,
  isEqual,
  dateReverseFormat,
  capitalizeFirstLetter,
  NameFormattor,
  OrderDateFormat,
  OrderTimeFormat,
  OrderListDateTimeFormat,
  isAvailable,
  LoanDate,
};
