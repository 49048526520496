import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  supplierBasicInfo: null,
  supplierKycInfo: null,
  supplierBankingInfo: null,
  supplierVerificationInfo: null,
  supplierDocumentInfo: null,
  supplierSignatureInfo: null,
  isSaved: false,
  billingCount : 0,
  shippingCount : 0,
};

const slice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // Reset LOADING
    requestReset(state) {
      state.isSaved = false;
      state.pageData = null;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.isSaved = false;
    },

    // GET SUPPLIER BASIC INFO
    getSupplierBasicInfoSuccess(state, action) {
      state.isLoading = false;
      state.shippingCount = action.payload.shippingAddresses.length;
      state.billingCount = action.payload.billingAddresses.length;
      action.payload?.shippingAddresses?.sort((a) => (a.is_default == 0) ? 1 : -1)
      action.payload?.billingAddresses?.sort((a) => (a.is_default == 0) ? 1 : -1)
      state.supplierBasicInfo = action.payload;
    },
    // GET SUPPLIER KYC INFO
    getSupplierKycInfoSuccess(state, action) {
      state.isLoading = false;
      state.supplierKycInfo = action.payload;
    },
    // GET SUPPLIER BANKING INFO
    getSupplierBankingInfoSuccess(state, action) {
      state.isLoading = false;
      state.supplierBankingInfo = action.payload;
    },
    // GET SUPPLIER VERIFICATION INFO
    getSupplierVerificationInfoSuccess(state, action) {
      state.isLoading = false;
      state.supplierVerificationInfo = action.payload;
    },
    // GET SUPPLIER DOCUMENT INFO
    getSupplierDocumentInfoSuccess(state, action) {
      state.isLoading = false;
      state.supplierDocumentInfo = action.payload;
    },
    //GET SUPPLIER SIGNATURE INFO
    getSupplierSignatureInfoSuccess(state, action) {
      state.isLoading = false;
      state.supplierSignatureInfo = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const { saveSupplierBasicInfoSuccess } = slice.actions;

// ----------------------------------------------------------------------
//reinit
export function resetLoading() {
  return async (dispatch) => {
    dispatch(slice.actions.requestReset());
  };
}
//get supplier basic info
export function getSupplierBasicInfo(requestData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        "/get-supplier-basic-info",
        requestData
      );
      dispatch(
        slice.actions.getSupplierBasicInfoSuccess(
          response.data.supplierBasicInfo
        )
      );
    } catch (error) {
      // 
      dispatch(slice.actions.hasError(error));
    }
  };
}

//get supplier kyc info
export function getSupplierKycInfo(requestData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/get-supplier-kyc-info", requestData);
      // 
      dispatch(
        slice.actions.getSupplierKycInfoSuccess(response.data.supplierKycInfo)
      );
    } catch (error) {
      // 
      dispatch(slice.actions.hasError(error));
    }
  };
}
//get supplier banking info
export function getSupplierBankingInfo(requestData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        "/get-supplier-banking-info",
        requestData
      );
      // 
      dispatch(
        slice.actions.getSupplierBankingInfoSuccess(
          response.data.supplierBankingInfo
        )
      );
    } catch (error) {
      // 
      dispatch(slice.actions.hasError(error));
    }
  };
}

//get supplier verification info
export function getSupplierVerificationInfo(requestData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        "/get-supplier-verification-info",
        requestData
      );
      // 
      dispatch(
        slice.actions.getSupplierVerificationInfoSuccess(
          response.data.supplierVerificationInfo
        )
      );
    } catch (error) {
      // 
      dispatch(slice.actions.hasError(error));
    }
  };
}

//get supplier document info
export function getSupplierDocumentInfo(requestData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        "/get-supplier-document-info",
        requestData
      );
      // 
      dispatch(
        slice.actions.getSupplierDocumentInfoSuccess(
          response.data.supplierDocumentInfo
        )
      );
    } catch (error) {
      // 
      dispatch(slice.actions.hasError(error));
    }
  };
}

//get supplier verification info
export function getSupplierSignatureInfo(requestData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        "/get-supplier-signature-info",
        requestData
      );
      // 
      dispatch(
        slice.actions.getSupplierSignatureInfoSuccess(
          response.data.supplierSignatureInfo
        )
      );
    } catch (error) {
      // 
      dispatch(slice.actions.hasError(error));
    }
  };
}
