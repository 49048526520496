import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  qtyLoading : false,
  loading: false,
  error: false,
  carts: [],
  CartCount : 0,
  isCartEmpty: false,
  subtotal : 0,
  user_id : null,
  cart_id : null,
  CartCountTmp : 0,
  subTotalTemp : 0
};

export const getAllcarts = createAsyncThunk(
  //action type string
  "cart/get-carts",
  // callback function
  async () => {
    const response = await axios.post(`/get-cart-items`);
    const cart_id  = response.data.cartData.id;
    const cart_items = response.data.cartData.cart_items;
    const total_price = response.data.cartData.total_price;
    const isEmpty = response.data.cartData.cart_items.length;
    return { cart_items, total_price, isEmpty, cart_id };
  }
);

export const addProductToCart = createAsyncThunk(
  //action type string
  "cart/add-to-cart",
  // callback function
  async ({ product_id, variant_id, price, qty, CartTotal, cart_type, quote_id }) => {
    let payload = {
      qty : qty.toString(),
      price,
      product_variant_id: parseInt(variant_id),
      product_id,
      total_price: CartTotal,
      cart_type,
      quote_id
    };

    const response = await axios.post(`customer/add-to-cart`, payload);
    dispatch(getAllcarts());

    return response.data;
  }
);
export const deletecart = createAsyncThunk(
  //action type string
  "cart/delete-cart",
  // callback function
  async (data) => {
    const { cart_id, cart_item_id, total_price } = data;
    const response = await axios.post(`delete-cart-item`, {
      id: cart_item_id,
      total_price: total_price,
      cart_id: cart_id,
    });

    dispatch(getAllcarts());
    return response.data;
  }
);

export const updateCart = createAsyncThunk(
  //action type string
  "cart/update-cart",
  // callback function
  async (item) => {
    const response = await axios.post(`update-cart-item`, item);
    dispatch(getAllcarts());
    return response.data;
  }
);

const slice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    requestReset(state, action) {
      state.isLoading= false;

    },
    resetCart(state) {
      state.CartCount = 0;
      state.carts = [];
      state.subtotal = 0;
    }
  },
  extraReducers: (builder) => {
    //Get carts
    builder.addCase(getAllcarts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllcarts.fulfilled, (state, action) => {
      const { cart_items, isEmpty, cart_id } = action.payload;
      cart_items.sort((a,b) => (b.id - a.id));
      state.cart_id = cart_id
      state.carts = cart_items;
      state.CartCount = cart_items.length
      state.subtotal = 0;
      state.subTotalTemp = 0
      cart_items.map((item) => {
        state.subTotalTemp += item.qty * item.price 
      })
      state.subtotal = state.subTotalTemp
      if (isEmpty === 0) {
        state.isCartEmpty = true;
      } else {
        state.isCartEmpty = false;
      }
      state.loading = false;
    });
    builder.addCase(getAllcarts.rejected, (state, action) => {
      state.loading = false;
    });
    //Update cart
    builder.addCase(updateCart.pending, (state) => {
      state.qtyLoading = true;
    });
    builder.addCase(updateCart.fulfilled, (state) => {
      
      state.qtyLoading = false
    });
    builder.addCase(updateCart.rejected, (state) => {
      
       state.qtyLoading = false
   
    });
    //Add cart
    builder.addCase(addProductToCart.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addProductToCart.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(addProductToCart.rejected, (state) => {
      state.loading = false;
    });

    // Delete cart
    builder.addCase(deletecart.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deletecart.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deletecart.rejected, (state) => {
      state.loading = false;
    });
  },
});

// Reducer
export default slice.reducer;

//reinit
export function resetLoading() {
  return async (dispatch) => {
    dispatch(slice.actions.requestReset());
  };
}

export function resetCart(){
  return async (dispatch) => {
    dispatch(slice.actions.resetCart());
  }
}
